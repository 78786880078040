import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { TroubledBorrowerController } from "@/api/loan/troubledBorrower";
import ProductionStatusState from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/productionStatusState";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import FormMixinBuilder from "@/store/shared/form";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import { ProductionStatusSnapshotKeys } from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/productionStatusSnapshotKeys";
import { cloneDeep } from "lodash";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import TroubledBorrowerState from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerState";
import storeManager from "@/store/manager";
import ApiDebtor from "@/api/loan/types/troubledBorrower/apiDebtor";
import mapper from "@/store/loan/modules/troubledBorrower/modules/productionStatus/mapper";
import Debtor from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtor";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ApiRequirement from "@/api/loan/types/troubledBorrower/apiRequirement";
import ApiDebtorEvent from "@/api/loan/types/troubledBorrower/apiDebtorEvent";
import DebtorEvent from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorEvent";
import StatusHistoryItem from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/statusHistoryItem";
import DebtorStatus from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorStatus";
import ApiDebtorStatus from "@/api/loan/types/troubledBorrower/apiDebtorStatus";
import ApiStatusHistoryItem from "@/api/loan/types/troubledBorrower/apiStatusHistoryItem";
import RequirementHistoryItem from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/requirementHistoryItem";
import ApiRequirementHistoryItem from "@/api/loan/types/troubledBorrower/apiRequirementHistoryItem";

const abortService = new AbortService();
const troubledBorrowerController = new TroubledBorrowerController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: ProductionStatusSnapshotKeys.EVENT,
			fields: ["editableEvent"]
		}),
		new SnapshotOptions({
			key: ProductionStatusSnapshotKeys.REQUIREMENT,
			fields: ["editableRequirement"]
		}),
		new SnapshotOptions({
			key: ProductionStatusSnapshotKeys.DEBTOR_STATUS_COMMENT,
			fields: ["editableDebtorStatusComment"]
		}),
		new SnapshotOptions({
			key: ProductionStatusSnapshotKeys.DEBTOR_STATUS,
			fields: ["editableDebtorStatus"]
		}),
		new SnapshotOptions({
			key: ProductionStatusSnapshotKeys.CREATE_DEBTOR_REQUEST,
			fields: ["createDebtorRequest"]
		}),
		new SnapshotOptions({
			key: ProductionStatusSnapshotKeys.UPDATE_DEBTOR_REQUEST,
			fields: ["updateDebtorRequest"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ProductionStatusState(
			new ListingModel<Debtor>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ProductionStatusState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items;
	},
	[getterTypes.version]: (state, getters, rootState) => {
		return resolveNestedState<TroubledBorrowerState>(rootState, storeManager.loan.troubledBorrower.namespace).version;
	},
	[getterTypes.obligedCompanies]: (state, getters, rootState) => {
		return resolveNestedState<TroubledBorrowerState>(rootState, storeManager.loan.troubledBorrower.namespace).obligedCompanies;
	},
	[getterTypes.debtor]: state => (id: string) => {
		return state.listing.items.find(x => x.debtorId === id);
	}
};

const actions = <ActionTree<ProductionStatusState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.updateListingItems);

		Object.keys(ProductionStatusSnapshotKeys).forEach(key => {
			commit(mutationTypes.SET_STATE_SNAPSHOT, key);
		});

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, getters }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let items = await troubledBorrowerController.getDebtors(getters.version.versionHeader.versionId);

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiDebtor, Debtor)));

			const requests: Promise<void>[] = [];

			items.forEach(x => {
				if(x.obligedCompanyId)
					requests.push((async () => {
						const company = await troubledBorrowerController.getCompany(`${x.obligedCompanyId}`);

						commit(mutationTypes.SET_DEBTOR_OBLIGED_COMPANY_NAME, { id: x.debtorId, value: company.name });
					})());
			});

			await Promise.all(requests);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.createDebtor]({ state, commit, getters }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const debtor = await troubledBorrowerController.createDebtor(getters.version.versionHeader.versionId,
				state.createDebtorRequest);

			commit(mutationTypes.ADD_DEBTOR, mapper.map(debtor, ApiDebtor, Debtor));

			if(debtor.obligedCompanyId)
				commit(mutationTypes.SET_DEBTOR_OBLIGED_COMPANY_NAME, {
					id: debtor.debtorId,
					value: getters.obligedCompanies.find((y: any) => y.id === debtor.obligedCompanyId).name
				});

			alertService.addInfo(AlertKeys.DEBTOR_SUCCESS_CREATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateDebtor]({ state, commit, getters }, id) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const debtor = await troubledBorrowerController.updateDebtor(getters.version.versionHeader.versionId,
				id,
				state.updateDebtorRequest);

			commit(mutationTypes.UPDATE_DEBTOR, mapper.map(debtor, ApiDebtor, Debtor));

			if(debtor.obligedCompanyId)
				commit(mutationTypes.SET_DEBTOR_OBLIGED_COMPANY_NAME, {
					id: debtor.debtorId,
					value: getters.obligedCompanies.find((y: any) => y.id === debtor.obligedCompanyId).name
				});

			alertService.addInfo(AlertKeys.DEBTOR_SUCCESS_UPDATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteDebtor]({ state, commit, getters }, debtor) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.deleteDebtor(getters.version.versionHeader.versionId, debtor.debtorId);

			commit(mutationTypes.REMOVE_DEBTOR, debtor);

			alertService.addInfo(AlertKeys.DEBTOR_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateDebtorStatus]({ commit, dispatch, getters, state }, { versionId, debtorId }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.updateDebtorStatus(getters.version.versionHeader.versionId,
				debtorId,
				mapper.map(state.editableDebtorStatus, DebtorStatus, ApiDebtorStatus));

			const statusHistoryItems = await troubledBorrowerController.getDebtorStatusHistory(versionId, debtorId);

			const mappedHistoryItems = statusHistoryItems.map(x => mapper.map(x, ApiStatusHistoryItem, StatusHistoryItem));

			commit(mutationTypes.SET_DEBTOR_STATUS_HISTORY,
				{
					id: debtorId,
					value: mappedHistoryItems
				});

			const [firstHistoryItem] = mappedHistoryItems;

			commit(mutationTypes.SET_DEBTOR_STATUS, { id: debtorId, value: firstHistoryItem });

			alertService.addInfo(AlertKeys.STATUS_SUCCESS_UPDATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteDebtorStatusHistoryItem]({ commit, getters }, { debtorId, statusId }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.deleteDebtorStatus(getters.version.versionHeader.versionId, debtorId, statusId);

			commit(mutationTypes.SET_DEBTOR_STATUS_HISTORY,
				{
					id: debtorId,
					value: getters.debtor(debtorId).statusHistory.filter((x: StatusHistoryItem) => x.statusId !== statusId)
				});
			commit(mutationTypes.SET_DEBTOR_EVENTS,
				{
					id: debtorId,
					value: getters.debtor(debtorId).events.filter((x: DebtorEvent) => x.debtorStatusHistoryItemId !== statusId)
				});

			const [firstHistoryItem] = state.listing.items[state.listing.items.findIndex(x => x.debtorId === debtorId)].statusHistory;

			commit(mutationTypes.SET_DEBTOR_STATUS, { id: debtorId, value: firstHistoryItem });

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.fetchDebtorRequirementHistoryItems]({ commit, getters }, { debtorId, requirementId }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const items = await troubledBorrowerController.getRequirementHistoryItems(getters.version.versionHeader.versionId,
				debtorId,
				requirementId);

			commit(mutationTypes.SET_DEBTOR_REQUIREMENTS_HISTORY,
				items.map(x => mapper.map(x, ApiRequirementHistoryItem, RequirementHistoryItem)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteDebtorRequirementHistoryItem]({ commit, getters }, { debtorId, requirementId, historyItemId }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.deleteRequirementHistory(getters.version.versionHeader.versionId,
				debtorId,
				requirementId,
				historyItemId);

			const items = state.debtorRequirementsHistory.filter((x: RequirementHistoryItem) => x.id !== historyItemId)
			commit(mutationTypes.SET_DEBTOR_REQUIREMENTS_HISTORY, items);

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.createRequirement]({ commit, dispatch, getters, state }, debtorId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const newItem = await troubledBorrowerController.createRequirement(getters.version.versionHeader.versionId,
				debtorId,
				state.editableRequirement);

			commit(mutationTypes.SET_DEBTOR_REQUIREMENTS, { id: debtorId, value: [...getters.debtor(debtorId).requirements, newItem] });

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_CREATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateRequirement]({ commit, getters, state }, debtorId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const requirementId = state.editableRequirement.requirementId;
			const requirement = state.editableRequirement;

			await troubledBorrowerController.updateRequirement(getters.version.versionHeader.versionId,
				debtorId,
				requirementId,
				requirement);

			commit(mutationTypes.SET_DEBTOR_REQUIREMENTS,
				{
					id: debtorId,
					value: getters.debtor(debtorId).requirements.map((x: ApiRequirement) => x.requirementId === requirementId ?
						requirement :
						x)
				});

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_UPDATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteRequirement]({ commit, getters }, { debtorId, requirementId }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.deleteRequirement(getters.version.versionHeader.versionId, debtorId, requirementId);

			commit(mutationTypes.SET_DEBTOR_REQUIREMENTS,
				{
					id: debtorId,
					value: getters.debtor(debtorId).requirements.filter((x: ApiRequirement) => x.requirementId !== requirementId)
				});

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.createEvent]({ commit, getters, state }, debtorId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const newItem = await troubledBorrowerController.createEvent(getters.version.versionHeader.versionId,
				debtorId,
				mapper.map(state.editableEvent, DebtorEvent, ApiDebtorEvent));

			const event = mapper.map(newItem, ApiDebtorEvent, DebtorEvent);

			commit(mutationTypes.SET_DEBTOR_EVENTS, { id: debtorId, value: [...getters.debtor(debtorId).events, event] });

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_CREATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateEvent]({ commit, getters, state }, debtorId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.updateEvent(getters.version.versionHeader.versionId, debtorId,
				mapper.map(state.editableEvent, DebtorEvent, ApiDebtorEvent));

			commit(mutationTypes.SET_DEBTOR_EVENTS,
				{
					id: debtorId,
					value: getters.debtor(debtorId).events.map((x: DebtorEvent) => x.eventId === state.editableEvent.eventId ?
						state.editableEvent : x)
				});

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_UPDATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteEvent]({ commit, getters }, { debtorId, eventId }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.deleteEvent(getters.version.versionHeader.versionId, debtorId, eventId);

			commit(mutationTypes.SET_DEBTOR_EVENTS,
				{
					id: debtorId,
					value: getters.debtor(debtorId).events.filter((x: DebtorEvent) => x.eventId !== eventId)
				});

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateDebtorStatusComment]({ commit, getters, state }, debtorId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.updateDebtorStatusComment(getters.version.versionHeader.versionId,
				debtorId,
				state.editableDebtorStatusComment);

			commit(mutationTypes.SET_DEBTOR_STATUS_COMMENT, { id: debtorId, value: state.editableDebtorStatusComment });

			alertService.addInfo(AlertKeys.COMMENT_SUCCESS_UPDATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<ProductionStatusState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_EDITABLE_EVENT](state, value) {
		state.editableEvent = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_EVENT_TYPE_ID](state, value) {
		state.editableEvent.eventTypeId = value;
	},
	[mutationTypes.RESET_EDITABLE_EVENT_TYPE_ID](state) {
		state.editableEvent.eventTypeId = "";
	},
	[mutationTypes.SET_EDITABLE_EVENT_DEBTOR_STATUS_HISTORY_ITEM_ID](state, value) {
		state.editableEvent.debtorStatusHistoryItemId = value;
	},
	[mutationTypes.SET_EDITABLE_EVENT_CONTROL_DATE](state, value) {
		state.editableEvent.controlDate = value;
	},
	[mutationTypes.SET_EDITABLE_EVENT_FACTUAL_DATE](state, value) {
		state.editableEvent.factualDate = value;
	},
	[mutationTypes.SET_EDITABLE_EVENT_JUDICIAL_DECISION_TYPE_ID](state, value) {
		state.editableEvent.judicialDecisionTypeId = value;
	},
	[mutationTypes.SET_EDITABLE_EVENT_FACTUAL_PENALTY_SUM](state, value) {
		state.editableEvent.factualPenaltySum = value;
	},
	[mutationTypes.SET_EDITABLE_REQUIREMENT](state, value) {
		state.editableRequirement = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_REQUIREMENT_TYPE_ID](state, value) {
		state.editableRequirement.requirementTypeId = value;
	},
	[mutationTypes.SET_EDITABLE_REQUIREMENT_SUM](state, value) {
		state.editableRequirement.sum = value;
	},
	[mutationTypes.SET_CREATE_DEBTOR_REQUEST](state, value) {
		state.createDebtorRequest = cloneDeep(value);
	},
	[mutationTypes.SET_CREATE_DEBTOR_REQUEST_COMPANY_ID](state, value) {
		state.createDebtorRequest.companyId = value;
	},
	[mutationTypes.SET_CREATE_DEBTOR_REQUEST_ROLE_IN_PROJECT](state, value) {
		state.createDebtorRequest.roleInProject = value;
	},
	[mutationTypes.SET_CREATE_DEBTOR_REQUEST_OBLIGED_COMPANY_ID](state, value) {
		state.createDebtorRequest.obligedCompanyId = value;
	},
	[mutationTypes.SET_EDITABLE_DEBTOR_STATUS_COMMENT](state, value) {
		state.editableDebtorStatusComment = value;
	},
	[mutationTypes.RESET_EDITABLE_DEBTOR_STATUS](state) {
		state.editableDebtorStatus = new DebtorStatus();
	},
	[mutationTypes.SET_EDITABLE_DEBTOR_STATUS](state, value) {
		state.editableDebtorStatus = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_DEBTOR_STATUS_TEXT](state, value) {
		state.editableDebtorStatus.text = value;
	},
	[mutationTypes.SET_EDITABLE_DEBTOR_STATUS_ID](state, value) {
		state.editableDebtorStatus.statusId = value;
	},
	[mutationTypes.SET_EDITABLE_DEBTOR_STATUS_TYPE_ID](state, value) {
		state.editableDebtorStatus.statusTypeId = value;
	},
	[mutationTypes.SET_EDITABLE_DEBTOR_STATUS_START_DATE](state, value) {
		state.editableDebtorStatus.startDate = value;
	},
	[mutationTypes.SET_EDITABLE_DEBTOR_STATUS_EXPIRATION_DATE](state, value) {
		state.editableDebtorStatus.expirationDate = value;
	},
	[mutationTypes.SET_DEBTOR_STATUS_COMMENT](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.debtorId === id)].comment = value;
	},
	[mutationTypes.SET_DEBTOR_REQUIREMENTS](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.debtorId === id)].requirements = cloneDeep(value);
	},
	[mutationTypes.SET_DEBTOR_EVENTS](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.debtorId === id)].events = cloneDeep(value);
	},
	[mutationTypes.SET_DEBTOR_STATUS](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.debtorId === id)].currentStatus = cloneDeep(value);
	},
	[mutationTypes.SET_DEBTOR_STATUS_HISTORY](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.debtorId === id)].statusHistory = cloneDeep(value);
	},
	[mutationTypes.SET_DEBTOR_OBLIGED_COMPANY_NAME](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.debtorId === id)].obligedCompanyName = cloneDeep(value);
	},
	[mutationTypes.REMOVE_DEBTOR](state, value) {
		state.listing.items.splice(state.listing.items.findIndex(x => x.debtorId === value.debtorId), 1);
	},
	[mutationTypes.ADD_DEBTOR](state, value) {
		state.listing.items.push(cloneDeep(value));
	},
	[mutationTypes.UPDATE_DEBTOR](state, value) {
		state.listing.items = state.listing.items.map(x => x.debtorId === value.debtorId ? cloneDeep(value) : x);
	},
	[mutationTypes.SET_UPDATE_DEBTOR_REQUEST_COMPANY_ID](state, value) {
		state.updateDebtorRequest.companyId = value;
	},
	[mutationTypes.SET_UPDATE_DEBTOR_REQUEST_ROLE_IN_PROJECT](state, value) {
		state.updateDebtorRequest.roleInProject = value;
	},
	[mutationTypes.SET_UPDATE_DEBTOR_REQUEST_OBLIGED_COMPANY_ID](state, value) {
		state.updateDebtorRequest.obligedCompanyId = value;
	},
	[mutationTypes.SET_DEBTOR_REQUIREMENTS_HISTORY](state, value) {
		state.debtorRequirementsHistory = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.troubledBorrower.productionStatus.namespace, mutationTypes.SET_EDITABLE_EVENT):
			{
				commit(resolveMutation(storeManager.loan.troubledBorrower.productionStatus.namespace, mutationTypes.SET_STATE_SNAPSHOT),
					ProductionStatusSnapshotKeys.EVENT);
				break;
			}
			case resolveMutation(storeManager.loan.troubledBorrower.productionStatus.namespace, mutationTypes.SET_EDITABLE_REQUIREMENT):
			{
				commit(resolveMutation(storeManager.loan.troubledBorrower.productionStatus.namespace, mutationTypes.SET_STATE_SNAPSHOT),
					ProductionStatusSnapshotKeys.REQUIREMENT);
				break;
			}
			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const productionStatusModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default productionStatusModule;
