export default class ApiRequirement {
	requirementId: string;
	versionId: string;
	debtorId: string;
	requirementTypeId: string;
	sum: number;

	constructor(
		requirementId: string = "",
		versionId: string = "",
		debtorId: string = "",
		requirementTypeId: string = "",
		sum: number = 0
	)
	{
		this.requirementId = requirementId;
		this.versionId = versionId;
		this.debtorId = debtorId;
		this.requirementTypeId = requirementTypeId;
		this.sum = sum;
	}
}
