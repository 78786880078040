export default class DebtorStatus {
	statusId: string;
	statusTypeId: string;
	updatedAt: number;
	modifiedBy: string;
	startDate: number;
	expirationDate: number;
	text: string;

	constructor(
		statusId: string = "",
		statusTypeId: string = "",
		updatedAt: number = 0,
		modifiedBy: string = "",
		startDate: number = 0,
		expirationDate: number = 0,
		text: string = ""
	)
	{
		this.statusId = statusId;
		this.statusTypeId = statusTypeId;
		this.updatedAt = updatedAt;
		this.modifiedBy = modifiedBy;
		this.startDate = startDate;
		this.expirationDate = expirationDate;
		this.text = text;
	}
}
