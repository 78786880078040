import ApiRefundSourceType from "@/api/loan/types/dictionaries/apiRefundSourceType";

export default class RefundSource {
	refundSourceType?: ApiRefundSourceType;
	startDate?: number;

	constructor(refundSourceType?: ApiRefundSourceType, startDate?: number) {
		this.refundSourceType = refundSourceType;
		this.startDate = startDate;
	}
}
