export default class ApiDebtorStatus {
	statusId: string;
	statusTypeId: string;
	updatedAt: string;
	modifiedBy: string;
	startDate: string;
	expirationDate: string;

	constructor(
		statusId: string = "",
		statusTypeId: string = "",
		updatedAt: string = "",
		modifiedBy: string = "",
		startDate: string = "",
		expirationDate: string = ""
	)
	{
		this.statusId = statusId;
		this.statusTypeId = statusTypeId;
		this.updatedAt = updatedAt;
		this.modifiedBy = modifiedBy;
		this.startDate = startDate;
		this.expirationDate = expirationDate;
	}
}
