<template>
	<v-card elevation="0" color="loan-card" class="mx-2">
		<v-row>
			<v-col class="d-flex justify-space-between align-center px-0" style="height: 48px;">
				<v-card-title class="text-h6 pa-0">{{ debtor.debtorCompanyName || "" }}</v-card-title>
				<div>
					<span class="text-subtitle-1 font-weight-medium">
							{{ projectRoleTypes.find(x => x.id === debtor.projectRoleId)?.name }}
							{{ debtor.obligedCompanyName ? `- ${debtor.obligedCompanyName}` : "" }}
					</span>
					<frp-btn v-if="can(Permissions.LOAN_UPDATE)"
							 small
							 @click="handleOpenUpdateDebtorDialog"
							 icon>
						<frp-icon src="ico_edit" :color="colors.primary.base"></frp-icon>
					</frp-btn>
					<frp-btn v-if="!debtor.events.length && can(Permissions.LOAN_DELETE)"
							 @click="handleOpenDeleteItemDialog(ProceedingStatusDeleteItemDialogType.DEBTOR)"
							 no-margin
							 small
							 icon>
						<frp-icon src="ico_delete" :color="colors.primary.base"></frp-icon>
					</frp-btn>
				</div>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="px-4 py-2 white darken-2 d-flex justify-space-between align-center">
				<template v-if="debtor.currentStatus?.statusTypeId && statusHistory.length === 1">
					<span>
						{{ getStatusItemText(debtor.currentStatus) }}
					</span>
				</template>

				<template v-else-if="statusHistory.length > 1">
					<frp-autocomplete v-model="internalFilterValues.statusHistoryIds"
									  :items="sortedStatusHistory"
									  multiple
									  transparent-multiple
									  item-text="text"
									  item-value="statusId"
									  color="blue"
									  hide-details
									  :placeholder="$t('fields.accrualType.placeholder')">
					</frp-autocomplete>
				</template>

				<template v-else>
					<span></span>
				</template>

				<div class="d-flex">
					<frp-btn v-if="can(Permissions.LOAN_UPDATE)"
							 small
							 @click="handleOpenDebtorStatusDialog"
							 icon>
						<frp-icon src="ico_edit" :color="colors.blue.darken4"></frp-icon>
					</frp-btn>
					<frp-btn small
							 @click="isDebtorStatusHistoryDialogOpened = true"
							 style="margin-left: 8px !important"
							 class="mr-1"
							 icon>
						<frp-icon src="ico_history" :color="colors.blue.darken4"></frp-icon>
					</frp-btn>
				</div>
			</v-col>
		</v-row>

		<template v-if="debtorFilteredEvents.length > 1">
			<v-row>
				<v-col cols="5">
					<frp-autocomplete v-model="internalFilterValues.eventStatus"
									  :items="filterEventsItems"
									  item-text="text"
									  item-value="value"
									  color="blue"
									  hide-details
									  :placeholder="$t('fields.accrualType.placeholder')">
					</frp-autocomplete>
				</v-col>
			</v-row>
		</template>

		<v-row>
			<v-col>
				<v-data-table :headers="headers"
							  :loading-text="$t('tables.loading')"
							  :loading="isItemsLoading"
							  hide-default-footer
							  item-key="id"
							  fixed-header
							  :items-per-page="-1"
							  :items="debtorFilteredEventsByEventStatus"
							  :mobile-breakpoint="mobileBreakpoint"
							  :options="options"
							  :item-class="(item) => `text-body-2 cy-table-row primary--text ${getRowJudicialDecisionClass(item.judicialDecisionTypeId)}`"
							  class="loan-table colored-table clickable-rows d-flex flex-column loan-scroll-table">
					<template #header.eventTypeId="{ header }">
						<div class="d-flex align-center flex-wrap" style="column-gap: 24px; row-gap: 4px">
							<span :class="header.class">{{ header.text }}</span>
							<frp-btn v-if="can(Permissions.LOAN_CREATE) && debtor.currentStatus"
									 class="text-none pl-1 pr-2 mb-1"
									 @click="handleOpenEventDialog(ProceedingStatusEditableItemModeType.CREATE, ProceedingStatusEventDialogType.EVENT)"
									 dense
									 outlined
									 :color="colors.blue.base">
								<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
								{{ $t("buttons.add") }}
							</frp-btn>
						</div>
					</template>

					<template #item.eventTypeId="{ item }">
						<div class="d-flex flex-column">
									<span :class="{ 'font-weight-bold': eventTypes.find(x => x.id === item.eventTypeId)?.isFinalGoal }">
										{{ eventTypes.find(x => x.id === item.eventTypeId)?.name }}
									</span>
							<span v-if="item.factualPenaltySum" class="text-subtitle-2 green--text mt-2">
									{{ `+${formatCurrency(item.factualPenaltySum, 0)} ₽` }}
								</span>
						</div>
					</template>

					<template #item.controlDate="{ item }">
						<span>{{ item.controlDate ? formatDate(item.controlDate, dateFormat) : "" }}</span>
					</template>

					<template #item.factualDate="{ item }">
						<span>{{ item.factualDate ? formatDate(item.factualDate, dateFormat) : "" }}</span>
					</template>

					<template #item.action1="{ item }">
						<frp-btn v-if="can(Permissions.LOAN_UPDATE)"
								 small
								 @click="handleOpenEventDialog(ProceedingStatusEditableItemModeType.UPDATE,
													 	eventTypes.find(x => x.id === item.eventTypeId).isFinalGoal ?
													 		ProceedingStatusEventDialogType.GOAL :
													 		ProceedingStatusEventDialogType.EVENT, item)"
								 icon>
							<frp-icon src="ico_edit" :color="colors.blue.darken4"></frp-icon>
						</frp-btn>
					</template>

					<template #item.action2="{ item }">
						<frp-btn v-if="can(Permissions.LOAN_DELETE)"
								 small
								 @click="handleOpenDeleteItemDialog(eventTypes.find(x => x.id === item.eventTypeId).isFinalGoal ?
													 	ProceedingStatusDeleteItemDialogType.GOAL :
													 	ProceedingStatusDeleteItemDialogType.EVENT, item)"
								 icon>
							<frp-icon src="ico_delete" :color="colors.primary.base"></frp-icon>
						</frp-btn>
					</template>
				</v-data-table>
				<v-data-table :loading-text="$t('tables.loading')"
							  :loading="isItemsLoading"
							  hide-default-footer
							  :mobile-breakpoint="mobileBreakpoint"
							  :options="options"
							  :item-class="() => 'text-body-2 cy-table-row primary--text'"
							  class="loan-table colored-table clickable-rows d-flex flex-column">>
					<template #body>
						<tfoot class="text-body-2 primary--text">
						<tr v-if="can(Permissions.LOAN_CREATE) && debtor.currentStatus">
							<td colspan="6">
								<frp-btn class="text-none pl-1 pr-2"
										 @click="handleOpenEventDialog(ProceedingStatusEditableItemModeType.CREATE, ProceedingStatusEventDialogType.GOAL)"
										 dense
										 outlined
										 :color="colors.blue.base">
									<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
									{{ $t("buttons.addFinalGoal") }}
								</frp-btn>
							</td>
						</tr>
						<tr>
							<td colspan="5" class="pb-0 pt-8">
								<span class="text-body-2 font-weight-bold">{{ $t("details.titles.currentDebtorStatusComment") }}</span>
							</td>
						</tr>
						<tr>
							<td colspan="3" class="pb-0" :class="debtor.comment ? 'pt-4' : 'pt-0'">
								<span v-if="debtor.comment">{{ debtor.comment }}</span>
								<span v-else class="grey--text">{{ $t("common.noComment") }}</span>
							</td>
							<td></td>
							<td style="width: 1%" class="py-0">
								<frp-btn v-if="can(Permissions.LOAN_UPDATE)"
										 small
										 @click="handleOpenDebtorStatusCommentDialog"
										 icon>
									<frp-icon src="ico_edit" :color="colors.blue.darken4"></frp-icon>
								</frp-btn>
							</td>
							<td style="width: 56px;"
								v-if="debtor.comment && !debtor.requirements.length || !debtor.comment && !debtor.requirements.length"></td>
						</tr>
						<tr>
							<td colspan="6">
								<div class="d-flex align-center">
										<span class="text-subtitle-1 font-weight-medium">
											{{ $t("titles.debtorRequirements") }}
										</span>
									<frp-btn v-if="can(Permissions.LOAN_CREATE)"
											 class="text-none pl-1 pr-2 ml-4"
											 @click="handleOpenRequirementDialog(ProceedingStatusEditableItemModeType.CREATE)"
											 dense
											 outlined
											 :color="colors.blue.base">
										<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
										{{ $t("buttons.add") }}
									</frp-btn>
								</div>
							</td>
						</tr>
						<template v-if="!debtor.requirements.length">
							<tr>
								<td colspan="5" align="center">
									<span class="text-body-2 grey--text">{{ $t("common.noData") }}</span>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr v-for="item in debtor.requirements" :key="item.requirementId">
								<td colspan="2">
									<span>
										{{
											`${requirementTypes.find(x => x.id ===
												item.requirementTypeId)?.name}, ${$t("common.inMillionRubles")}`
										}}
									</span>
								</td>
								<td>
									<span>{{ item.sum }}</span>
								</td>
								<td style="width: 1%">
									<frp-btn small
											 @click="handleOpenDebtorRequirementHistoryDialog(item.requirementId)"
											 icon>
										<frp-icon src="ico_history" :color="colors.blue.darken4"></frp-icon>
									</frp-btn>
								</td>
								<td style="width: 1%">
									<frp-btn v-if="can(Permissions.LOAN_UPDATE)"
											 small
											 @click="handleOpenRequirementDialog(ProceedingStatusEditableItemModeType.UPDATE, item)"
											 icon>
										<frp-icon src="ico_edit" :color="colors.blue.darken4"></frp-icon>
									</frp-btn>
								</td>
								<td style="width: 1%">
									<frp-btn v-if="can(Permissions.LOAN_DELETE)"
											 small
											 @click="handleOpenDeleteItemDialog(ProceedingStatusDeleteItemDialogType.REQUIREMENT, item)"
											 icon>
										<frp-icon src="ico_delete" :color="colors.primary.base"></frp-icon>
									</frp-btn>
								</td>
							</tr>
						</template>
						</tfoot>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<frp-dialog v-model="isDeleteItemDialogOpened" :title="deleteItemDialogTitle">
			<template #content>
				<span class="primary--text text-body-2">{{ deleteItemDialogText }}</span>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="isDeleteItemDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 :loading="isFormSaving"
						 @click="handleDeleteItem"
						 :color="colors.blue.base">
					<span class="white--text">{{ $t("buttons.remove") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog max-width="565"
					v-model="isDebtorStatusHistoryDialogOpened"
					:title="$t('dialogs.debtorStatusHistory.title')">
			<template #content="{ onIntersect }">
				<div class="d-flex justify-center flex-column" style="gap: 8px" v-intersect="onIntersect">
					<frp-text-body-two v-if="!debtor.statusHistory?.length"
									   class="grey--text align-self-center mt-6 mb-3">
						{{ $t("dialogs.debtorStatusHistory.noDataText") }}
					</frp-text-body-two>
					<div class="d-flex justify-space-between align-center" v-else v-for="(item, i) in sortedStatusHistory" :key="i">
						<div class="d-flex flex-column justify-center">
							<span class="text-subtitle-2 primary--text">
								{{ getStatusHistoryDialogItemText(item) }}
							</span>
							<span class="text-caption grey--text mt-1">
								{{ `${item.modifiedBy}, ${formatDate(item.updatedAt, dateFormat)}` }}
							</span>
						</div>
						<frp-btn v-if="can(Permissions.LOAN_DELETE)"
								 small
								 @click="handleOpenDeleteItemDialog(ProceedingStatusDeleteItemDialogType.DEBTOR_STATUS, item)"
								 icon>
							<frp-icon src="ico_delete" :color="colors.primary.base"></frp-icon>
						</frp-btn>
					</div>
				</div>
			</template>

			<template #footer>
				<frp-btn class="elevation-0"
						 @click="isDebtorStatusHistoryDialogOpened = false"
						 :color="colors.blue.base">
					<span class="white--text">{{ $t("buttons.close") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog max-width="565"
					v-model="isDebtorRequirementHistoryDialogOpened"
					:title="$t('dialogs.debtorRequirementsHistory.title')">
			<template #content="{ onIntersect }">
				<div class="d-flex justify-center flex-column" style="gap: 8px" v-intersect="onIntersect">
					<frp-text-body-two v-if="!debtorRequirementsHistory?.length"
									   class="grey--text align-self-center mt-6 mb-3">
						{{ $t("dialogs.debtorRequirementsHistory.noDataText") }}
					</frp-text-body-two>
					<div class="d-flex justify-space-between align-center" v-else v-for="(item, i) in sortedRequirementHistory" :key="i">
						<div class="d-flex flex-column justify-center">
							<span class="text-subtitle-2 primary--text">
								{{
									`${requirementTypes.find(x => x.id === item.debtorRequirementTypeId)?.name}, ${$t(
										"common.inMillionRubles")} - ${item.sum}`
								}}
							</span>
							<span class="text-caption grey--text mt-1">
								{{ `${item.createdBy}, ${formatDate(item.updatedAt, dateFormat)}` }}
							</span>
						</div>
						<frp-btn v-if="can(Permissions.LOAN_DELETE)"
								 small
								 @click="handleOpenDeleteItemDialog(ProceedingStatusDeleteItemDialogType.REQUIREMENT_HISTORY_ITEM, item)"
								 icon>
							<frp-icon src="ico_delete" :color="colors.primary.base"></frp-icon>
						</frp-btn>
					</div>
				</div>
			</template>

			<template #footer>
				<frp-btn class="elevation-0"
						 @click="isDebtorRequirementHistoryDialogOpened = false"
						 :color="colors.blue.base">
					<span class="white--text">{{ $t("buttons.close") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isDebtorStatusDialogOpened" :title="$t('dialogs.updateDebtorStatus.title')" persistent>
			<template #content="{ onIntersect }">
				<v-form v-intersect="onIntersect" ref="debtorStatusForm" v-model="isUpdateStatusFormValid">
					<frp-autocomplete :label="$t('fields.status.label')"
									  v-model="editableDebtorStatusTypeId"
									  :items="statuses"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.status.placeholder')">
					</frp-autocomplete>
					<frp-date-field :label="$t('fields.startDate.label')"
									required
									v-model="editableDebtorStatusStartDate">
					</frp-date-field>
					<frp-date-field :label="$t('fields.expirationDate.label')"
									v-model="editableDebtorStatusExpirationDate">
					</frp-date-field>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="handleResetDebtorStatusDialog"
						 :disabled="isFormSaving"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 :color="colors.blue.base"
						 :loading="isFormSaving"
						 :disabled="!isUpdateStatusFormValid || !isStateContainsUnsavedChanges(ProceedingStatusSnapshotKeys.DEBTOR_STATUS)"
						 @click="handleUpdateDebtorStatusBtnClick">
					<span class="white--text">{{ $t("buttons.save") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isRequirementDialogOpened" :title="requirementDialogTitle" persistent>
			<template #content="{ onIntersect }">
				<v-form v-intersect="onIntersect" ref="requirementForm" v-model="isRequirementFormValid">
					<frp-autocomplete :label="$t('fields.requirement.label')"
									  item-text="name"
									  item-value="id"
									  v-model="editableRequirementTypeId"
									  :items="requirementTypes"
									  color="blue"
									  required
									  :placeholder="$t('fields.requirement.placeholder')">
					</frp-autocomplete>
					<frp-text-field :label="$t('fields.amount.labelRUB')"
									v-model.number="editableRequirementSum"
									type="number"
									required
									:placeholder="$t('fields.amount.placeholder')">
					</frp-text-field>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="isRequirementDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 :color="colors.blue.base"
						 :loading="isFormSaving"
						 :disabled="!isRequirementFormValid || !isStateContainsUnsavedChanges(ProceedingStatusSnapshotKeys.REQUIREMENT)"
						 @click="handleRequirementBtnClick">
					<span class="white--text">{{ $t("buttons.save") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isEventDialogOpened" :title="eventDialogTitle" persistent>
			<template #content="{ onIntersect }">
				<v-form v-intersect="onIntersect" ref="eventForm" v-model="isEventFormValid">
					<frp-autocomplete :label="$t('fields.relatedStatus.label')"
									  item-text="text"
									  return-object
									  v-model="editableEventDebtorStatusHistoryItem"
									  :items="sortedStatusHistory"
									  required
									  color="blue"
									  :placeholder="$t('fields.relatedStatus.placeholder')">
					</frp-autocomplete>
					<frp-autocomplete :label="$t('fields.event.label')"
									  item-text="name"
									  item-value="id"
									  v-model="editableEventTypeId"
									  :items="events"
									  required
									  color="blue"
									  :placeholder="$t('fields.event.label')">
					</frp-autocomplete>
					<frp-date-field :label="$t('fields.controlDate.label')"
									v-model="editableEventControlDate"
									required>
					</frp-date-field>
					<frp-date-field :label="$t('fields.factualDate.label')"
									v-model="editableEventFactualDate">
					</frp-date-field>
					<frp-autocomplete :label="$t('fields.judicialDecision.label')"
									  item-text="name"
									  item-value="id"
									  v-model="editableEventJudicialDecisionTypeId"
									  :items="judicialDecisionTypes"
									  color="blue"
									  :placeholder="$t('fields.judicialDecision.placeholder')">
					</frp-autocomplete>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="handleCloseEventDialog"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 :color="colors.blue.base"
						 :loading="isFormSaving"
						 :disabled="!isEventFormValid || !isStateContainsUnsavedChanges(ProceedingStatusSnapshotKeys.EVENT)"
						 @click="handleEventBtnClick">
					<span class="white--text">{{ $t("buttons.save") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isDebtorStatusCommentDialogOpened" :title="$t('dialogs.updateStatusComment.title')" persistent>
			<template #content>
				<v-form v-model="isDebtorStatusCommentFormValid">
					<frp-textarea v-model="editableDebtorStatusComment"
								  :label="$t('fields.comment.label')"
								  :placeholder="$t('fields.comment.placeholder')"
								  :rules="validation.debtorStatusComment"
								  :counter="maxLengths.text">
					</frp-textarea>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="isDebtorStatusCommentDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 :color="colors.blue.base"
						 :loading="isFormSaving"
						 :disabled="!isStateContainsUnsavedChanges(ProceedingStatusSnapshotKeys.DEBTOR_STATUS_COMMENT) || !isDebtorStatusCommentFormValid"
						 @click="handleUpdateDebtorStatusCommentBtnClick">
					<span class="white--text">{{ $t("buttons.save") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isUpdateDebtorDialogOpened" :title="$t('dialogs.updateDebtor.title')" persistent>
			<template #content>
				<v-form :ref="refs.form" v-model="isDebtorFormValid">
					<frp-autocomplete :label="$t('fields.organization.label')"
									  v-model="updateDebtorRequestCompanyId"
									  :min-query-length="MIN_ORGANIZATIONS_QUERY_LENGTH"
									  :items="organizations"
									  @update:search-input="getOrganizations"
									  item-value="id"
									  item-text="name"
									  color="blue"
									  required
									  :loading="isOrganizationsLoading"
									  :placeholder="$t('fields.organization.placeholder')">
					</frp-autocomplete>

					<div class="d-flex align-center justify-end">
						<span class="text-caption blue--text text--darken-4 mt-1">{{ $t("common.counterpartyIsMissing") }}</span>
						<frp-btn class="text-none"
								 :href="addCounterpartyUrl"
								 text
								 plain
								 :color="colors.blue.base">
							<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
							{{ $t("buttons.add") }}
						</frp-btn>
					</div>

					<frp-autocomplete :label="$t('fields.roleInProject.label')"
									  v-model="updateDebtorRequestRoleInProject"
									  :items="projectRoleTypes"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.roleInProject.placeholder')">
					</frp-autocomplete>

					<frp-autocomplete :label="$t('fields.obligedCompany.label')"
									  v-if="isObligedCompanyFieldVisible"
									  v-model="updateDebtorRequestObligedCompanyId"
									  :min-query-length="MIN_ORGANIZATIONS_QUERY_LENGTH"
									  :items="obligedCompanies"
									  @update:search-input="getOrganizations($event, true)"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :loading="isObligedCompaniesLoading"
									  :placeholder="$t('fields.obligedCompany.placeholder')">
					</frp-autocomplete>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="isUpdateDebtorDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 :color="colors.blue.base"
						 :loading="isFormSaving"
						 :disabled="!isStateContainsUnsavedChanges(ProceedingStatusSnapshotKeys.UPDATE_DEBTOR_REQUEST) || !isDebtorFormValid"
						 @click="handleDebtorBtnClick">
					<span class="white--text">{{ $t("buttons.save") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
	</v-card>
</template>

<script>
import FrpFilter from "@/components/common/FrpFilter.vue";
import DebtorStatus from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorStatus";
import { JudicialDecisionType } from "@/store/loan/modules/troubledBorrower/types/judicialDecisionType";
import TroubledBorrowerFilterEventsType from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerFilterEventsType";
import { deleteDoublesInArrayByKeyName } from "@/utils/array";
import ApiRequirement from "Api/loan/types/troubledBorrower/apiRequirement";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import FrpTextBodyTwo from "Components/typography/FrpTextBodyTwo";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import BatchService from "Services/batchService";
import { MIN_ORGANIZATIONS_QUERY_LENGTH, ROLES_FOR_OBLIGED_COMPANY } from "Store/loan/modules/troubledBorrower/constants";
import {
	ProductionStatusDeleteItemDialogType
} from "Store/loan/modules/troubledBorrower/modules/productionStatus/types/productionStatusDeleteItemDialogType";
import {
	ProductionStatusEditableItemModeType
} from "Store/loan/modules/troubledBorrower/modules/productionStatus/types/productionStatusEditableItemModeType";
import {
	ProductionStatusEventDialogType
} from "Store/loan/modules/troubledBorrower/modules/productionStatus/types/productionStatusEventDialogType";
import {
	ProductionStatusSnapshotKeys
} from "Store/loan/modules/troubledBorrower/modules/productionStatus/types/productionStatusSnapshotKeys";
import DebtorEvent from "Store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorEvent";
import { actionTypes as troubledBorrowerActionTypes } from "Store/loan/modules/troubledBorrower/types";
import { formatDate } from "Utils/dates";
import { dateFormat, dateWithSecondsFormat } from "Utils/formats";
import { formatCurrency } from "Utils/formatting";
import { maxLengths, prepareMaxLengthRule } from "Utils/validation";
import {
	getterTypes,
	mutationTypes,
	actionTypes
} from "Store/loan/modules/troubledBorrower/modules/productionStatus/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";
import { assign, first, isEqual, orderBy } from "lodash";

const namespace = storeManager.loan.troubledBorrower.productionStatus.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const troubledBorrower = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

const requestBatchService = new BatchService(({ interval: 500 }));

export default {
	mixins: [colorsMixin, listMixin, formMixin, authorizationMixin],
	props: {
		debtor: Object
	},
	data() {
		return {
			namespace,
			formatCurrency,
			orderBy,
			dateFormat,
			dateWithSecondsFormat,
			formatDate,
			deleteDoublesInArrayByKeyName,
			maxLengths,
			first,
			MIN_ORGANIZATIONS_QUERY_LENGTH,
			addCounterpartyUrl: process.env.VUE_APP_OLK_BASE_URL,
			TroubledBorrowerFilterEventsType,
			ProductionStatusDeleteItemDialogType,
			ProductionStatusEventDialogType,
			ProductionStatusEditableItemModeType,
			ProceedingStatusSnapshotKeys: ProductionStatusSnapshotKeys,
			ProceedingStatusDeleteItemDialogType: ProductionStatusDeleteItemDialogType,
			ProceedingStatusEventDialogType: ProductionStatusEventDialogType,
			ProceedingStatusEditableItemModeType: ProductionStatusEditableItemModeType,
			isUpdateDebtorDialogOpened: false,
			isUpdateDebtorDialogLoading: false,
			isDeleteItemDialogOpened: false,
			isDebtorStatusHistoryDialogOpened: false,
			isDebtorStatusDialogOpened: false,
			isEventDialogOpened: false,
			isDebtorRequirementHistoryDialogOpened: false,
			isRequirementDialogOpened: false,
			isDebtorStatusCommentDialogOpened: false,
			deleteItemDialogType: ProductionStatusDeleteItemDialogType.UNKNOWN,
			eventDialogType: ProductionStatusEventDialogType.UNKNOWN,
			editableItemModeType: ProductionStatusEditableItemModeType.UNKNOWN,
			isDebtorFormValid: false,
			isUpdateStatusFormValid: false,
			isEventFormValid: false,
			isRequirementFormValid: false,
			itemToDelete: null,
			validation: {
				debtorStatusComment: [prepareMaxLengthRule({ maxLength: maxLengths.text })]
			},
			isDebtorStatusCommentFormValid: false,
			internalFilterValues: {
				eventStatus: TroubledBorrowerFilterEventsType.ALL,
				statusHistoryIds: [this.debtor.currentStatus?.statusId]
			},
			filterEventsItems: [
				{
					text: this.$t(`aliases.troubledBorrowerFilterEventsType.${TroubledBorrowerFilterEventsType.ALL}`),
					value: TroubledBorrowerFilterEventsType.ALL
				},
				{
					text: this.$t(`aliases.troubledBorrowerFilterEventsType.${TroubledBorrowerFilterEventsType.PAST}`),
					value: TroubledBorrowerFilterEventsType.PAST
				},
				{
					text: this.$t(`aliases.troubledBorrowerFilterEventsType.${TroubledBorrowerFilterEventsType.PLANNED}`),
					value: TroubledBorrowerFilterEventsType.PLANNED
				}
			]
		};
	},
	computed: {
		...troubledBorrower.mapState({
			organizations: state => state.organizations,
			obligedCompanies: state => state.obligedCompanies,
			debtorStatusTypes: state => state.debtorStatusTypes,
			requirementTypes: state => state.requirementTypes,
			eventTypes: state => state.eventTypes,
			judicialDecisionTypes: state => state.judicialDecisionTypes,
			projectRoleTypes: state => state.projectRoleTypes,
			isOrganizationsLoading: state => state.isOrganizationsLoading,
			isObligedCompaniesLoading: state => state.isObligedCompaniesLoading
		}),
		...mapState({
			state: state => state,
			editableEvent: state => state.editableEvent,
			editableRequirement: state => state.editableRequirement,
			editableDebtorStatus: state => state.editableDebtorStatus,
			editableDebtorStatusComment: state => state.editableDebtorStatusComment,
			updateDebtorRequest: state => state.updateDebtorRequest,
			debtorRequirementsHistory: state => state.debtorRequirementsHistory
		}),
		headers() {
			return [
				{
					text: this.$t("tables.event"),
					value: "eventTypeId",
					class: "text-caption",
					width: "60%",
					sortable: false
				},
				{
					text: this.$t("tables.controlDate"),
					value: "controlDate",
					class: "text-caption",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.factualDate"),
					value: "factualDate",
					class: "text-caption",
					width: "20%",
					sortable: false
				},
				{
					text: "",
					value: "none",
					class: "text-caption",
					width: "1%",
					sortable: false
				},
				{
					text: "",
					value: "action1",
					class: "text-caption",
					width: "1%",
					sortable: false
				},
				{
					text: "",
					value: "action2",
					class: "text-caption",
					width: "1%",
					sortable: false
				}
			];
		},
		debtorFilteredEvents() {
			const filteredEvents = this.debtor.events.filter(x => x.debtorStatusHistoryItemId ? this.internalFilterValues.statusHistoryIds.includes(x.debtorStatusHistoryItemId) : x);
			return orderBy(filteredEvents, ["controlDate", "factualDate"], ['asc']);
		},
		debtorFilteredEventsByEventStatus() {
			switch (this.internalFilterValues.eventStatus) {
				case TroubledBorrowerFilterEventsType.PAST:
					return this.debtorFilteredEvents.filter(x => x.factualDate);
				case TroubledBorrowerFilterEventsType.PLANNED:
					return this.debtorFilteredEvents.filter(x => !x.factualDate);
				default:
					return this.debtorFilteredEvents;
			}
		},
		statusHistory() {
			return this.debtor.statusHistory.map(x => {
				const text = this.getStatusItemText(x);

				return {
					...x,
					text
				};
			});
		},
		filteredEventTypes() {
			return this.eventTypes.filter(x => (this.eventDialogType === ProductionStatusEventDialogType.GOAL ?
				x.isFinalGoal :
				!x.isFinalGoal));
		},
		sortedStatusHistory() {
			return this.sortItemsByDate(this.statusHistory, 'updatedAt');
		},
		sortedRequirementHistory() {
			return this.sortItemsByDate(this.debtorRequirementsHistory, 'updatedAt');
		},
		events() {
			const deletedDoublesFilteredEventTypes = deleteDoublesInArrayByKeyName(this.filteredEventTypes, 'name');

			if(this.editableDebtorStatus?.statusTypeId) {
				const filteredEvents = this.eventTypes.filter(x => (x.statusTypeId === this.editableDebtorStatus.statusTypeId) &&
					(this.eventDialogType === ProductionStatusEventDialogType.GOAL ? x.isFinalGoal : !x.isFinalGoal));

				const deletedDoublesEvents = deleteDoublesInArrayByKeyName(filteredEvents, 'name');

				return deletedDoublesEvents.length ? deletedDoublesEvents : deletedDoublesFilteredEventTypes;

			} else {
				return deletedDoublesFilteredEventTypes;
			}
		},
		statuses() {
			return this.debtorStatusTypes.filter(x => this.debtor.isLegalEntity ? x.canBeLegalEntity : x.canBeIndividual);
		},
		requirementDialogTitle() {
			switch (this.editableItemModeType) {
				case ProductionStatusEditableItemModeType.CREATE:
					return this.$t("dialogs.addRequirement.title");
				case ProductionStatusEditableItemModeType.UPDATE:
					return this.$t("dialogs.updateRequirement.title");
			}
		},
		eventDialogTitle() {
			if(this.eventDialogType === ProductionStatusEventDialogType.EVENT) {
				if(this.editableItemModeType === ProductionStatusEditableItemModeType.CREATE)
					return this.$t("dialogs.addEvent.title");
				if(this.editableItemModeType === ProductionStatusEditableItemModeType.UPDATE)
					return this.$t("dialogs.updateEvent.title");
			}

			if(this.eventDialogType === ProductionStatusEventDialogType.GOAL) {
				if(this.editableItemModeType === ProductionStatusEditableItemModeType.CREATE)
					return this.$t("dialogs.addFinalGoal.title");
				if(this.editableItemModeType === ProductionStatusEditableItemModeType.UPDATE)
					return this.$t("dialogs.updateFinalGoal.title");
			}
		},
		deleteItemDialogTitle() {
			switch (this.deleteItemDialogType) {
				case ProductionStatusDeleteItemDialogType.DEBTOR:
					return this.$t("dialogs.deleteDebtor.title");
				case ProductionStatusDeleteItemDialogType.DEBTOR_STATUS:
					return this.$t("dialogs.deleteStatusHistoryItem.title");
				case ProductionStatusDeleteItemDialogType.EVENT:
					return this.$t("dialogs.deleteEvent.title");
				case ProductionStatusDeleteItemDialogType.GOAL:
					return this.$t("dialogs.deleteFinalGoal.title");
				case ProductionStatusDeleteItemDialogType.REQUIREMENT:
					return this.$t("dialogs.deleteRequirement.title");
				case ProductionStatusDeleteItemDialogType.REQUIREMENT_HISTORY_ITEM:
					return this.$t("dialogs.deleteRequirementHistoryItem.title");
			}
		},
		deleteItemDialogText() {
			switch (this.deleteItemDialogType) {
				case ProductionStatusDeleteItemDialogType.DEBTOR:
					return this.$t("dialogs.deleteDebtor.text");
				case ProductionStatusDeleteItemDialogType.DEBTOR_STATUS:
					return this.$t("dialogs.deleteStatusHistoryItem.text");
				case ProductionStatusDeleteItemDialogType.EVENT:
					return this.$t("dialogs.deleteEvent.text");
				case ProductionStatusDeleteItemDialogType.GOAL:
					return this.$t("dialogs.deleteFinalGoal.text");
				case ProductionStatusDeleteItemDialogType.REQUIREMENT:
					return this.$t("dialogs.deleteRequirement.text");
				case ProductionStatusDeleteItemDialogType.REQUIREMENT_HISTORY_ITEM:
					return this.$t("dialogs.deleteRequirementHistoryItem.text");
			}
		},
		isObligedCompanyFieldVisible() {
			return ROLES_FOR_OBLIGED_COMPANY.includes(this.projectRoleTypes.find(x => x.id ===
				this.updateDebtorRequestRoleInProject)?.name);
		},
		editableEventTypeId: {
			get() {
				return this.editableEvent.eventTypeId;
			},
			set(value) {
				this.setEditableEventTypeId(value);
			}
		},
		editableEventDebtorStatusHistoryItem: {
			get() {
				return this.editableDebtorStatus;
			},
			set(value) {
				if(!value) return;

				this.setEditableDebtorStatus(value);
				this.setEditableDebtorStatusText(this.getStatusItemText(value));
				this.setEditableEventDebtorStatusHistoryItemId(value.statusId);
				this.resetEditableEventTypeId();
			}
		},
		editableEventControlDate: {
			get() {
				return this.editableEvent.controlDate;
			},
			set(value) {
				this.setEditableEventControlDate(value);
			}
		},
		editableEventFactualDate: {
			get() {
				return this.editableEvent.factualDate;
			},
			set(value) {
				this.setEditableEventFactualDate(value);
			}
		},
		editableEventFactualPenaltySum: {
			get() {
				return this.editableEvent.factualPenaltySum;
			},
			set(value) {
				this.setEditableEventFactualPenaltySum(value);
			}
		},
		editableEventJudicialDecisionTypeId: {
			get() {
				return this.editableEvent.judicialDecisionTypeId;
			},
			set(value) {
				this.setEditableEventJudicialDecisionTypeId(value);
			}
		},
		editableRequirementTypeId: {
			get() {
				return this.editableRequirement.requirementTypeId;
			},
			set(value) {
				this.setEditableRequirementTypeId(value);
			}
		},
		editableRequirementSum: {
			get() {
				return this.editableRequirement.sum;
			},
			set(value) {
				this.setEditableRequirementSum(value);
			}
		},
		editableDebtorStatusComment: {
			get() {
				return this.state.editableDebtorStatusComment;
			},
			set(value) {
				this.setEditableDebtorStatusComment(value);
			}
		},
		editableDebtorStatusTypeId: {
			get() {
				return this.state.editableDebtorStatus?.statusTypeId;
			},
			set(value) {
				this.setEditableDebtorStatusTypeId(value);
				this.setEditableDebtorStatusStartDate(null);
				this.setEditableDebtorStatusExpirationDate(null);
				this.$refs.debtorStatusForm.resetValidation();
			}
		},
		editableDebtorStatusStartDate: {
			get() {
				return this.state.editableDebtorStatus.startDate;
			},
			set(value) {
				this.setEditableDebtorStatusStartDate(value);
			}
		},
		editableDebtorStatusExpirationDate: {
			get() {
				return this.state.editableDebtorStatus.expirationDate;
			},
			set(value) {
				this.setEditableDebtorStatusExpirationDate(value);
			}
		},
		updateDebtorRequestCompanyId: {
			get() {
				return this.updateDebtorRequest.companyId;
			},
			set(value) {
				this.setUpdateDebtorRequestCompanyId(value);
			}
		},
		updateDebtorRequestRoleInProject: {
			get() {
				return this.updateDebtorRequest.roleInProject;
			},
			set(value) {
				this.setUpdateDebtorRequestRoleInProject(value);
			}
		},
		updateDebtorRequestObligedCompanyId: {
			get() {
				return this.updateDebtorRequest.obligedCompanyId;
			},
			set(value) {
				this.setUpdateDebtorRequestObligedCompanyId(value);
			}
		}
	},
	methods: {
		...troubledBorrower.mapActions({
			fetchOrganizations: troubledBorrowerActionTypes.fetchOrganizations,
			fetchObligedCompanies: troubledBorrowerActionTypes.fetchObligedCompanies
		}),
		...mapActions({
			addEditableActualStatus: actionTypes.addEditableActualStatus,
			fetchDebtorRequirementHistoryItems: actionTypes.fetchDebtorRequirementHistoryItems,
			deleteDebtor: actionTypes.deleteDebtor,
			deleteDebtorStatusHistoryItem: actionTypes.deleteDebtorStatusHistoryItem,
			deleteDebtorRequirementHistoryItem: actionTypes.deleteDebtorRequirementHistoryItem,
			deleteEvent: actionTypes.deleteEvent,
			deleteGoal: actionTypes.deleteGoal,
			deleteRequirement: actionTypes.deleteRequirement,
			updateDebtorStatus: actionTypes.updateDebtorStatus,
			updateDebtorStatusComment: actionTypes.updateDebtorStatusComment,
			createEvent: actionTypes.createEvent,
			updateEvent: actionTypes.updateEvent,
			createGoal: actionTypes.createGoal,
			updateGoal: actionTypes.updateGoal,
			createRequirement: actionTypes.createRequirement,
			updateRequirement: actionTypes.updateRequirement,
			rollback: actionTypes.cancelChanges,
			updateDebtor: actionTypes.updateDebtor
		}),
		...mapMutations({
			resetEditableEventTypeId: mutationTypes.RESET_EDITABLE_EVENT_TYPE_ID,
			setEditableEventTypeId: mutationTypes.SET_EDITABLE_EVENT_TYPE_ID,
			setEditableEventDebtorStatusHistoryItemId: mutationTypes.SET_EDITABLE_EVENT_DEBTOR_STATUS_HISTORY_ITEM_ID,
			setEditableEventControlDate: mutationTypes.SET_EDITABLE_EVENT_CONTROL_DATE,
			setEditableEventFactualDate: mutationTypes.SET_EDITABLE_EVENT_FACTUAL_DATE,
			setEditableEventFactualPenaltySum: mutationTypes.SET_EDITABLE_EVENT_FACTUAL_PENALTY_SUM,
			setEditableEventJudicialDecisionTypeId: mutationTypes.SET_EDITABLE_EVENT_JUDICIAL_DECISION_TYPE_ID,
			setEditableRequirementTypeId: mutationTypes.SET_EDITABLE_REQUIREMENT_TYPE_ID,
			setEditableRequirementSum: mutationTypes.SET_EDITABLE_REQUIREMENT_SUM,
			setEditableDebtorStatusComment: mutationTypes.SET_EDITABLE_DEBTOR_STATUS_COMMENT,
			resetEditableDebtorStatus: mutationTypes.RESET_EDITABLE_DEBTOR_STATUS,
			setEditableDebtorStatus: mutationTypes.SET_EDITABLE_DEBTOR_STATUS,
			setEditableDebtorStatusTypeId: mutationTypes.SET_EDITABLE_DEBTOR_STATUS_TYPE_ID,
			setEditableDebtorStatusText: mutationTypes.SET_EDITABLE_DEBTOR_STATUS_TEXT,
			setEditableDebtorStatusStartDate: mutationTypes.SET_EDITABLE_DEBTOR_STATUS_START_DATE,
			setEditableDebtorStatusExpirationDate: mutationTypes.SET_EDITABLE_DEBTOR_STATUS_EXPIRATION_DATE,
			setStateSnapshot: mutationTypes.SET_STATE_SNAPSHOT,
			setEditableEvent: mutationTypes.SET_EDITABLE_EVENT,
			setEditableRequirement: mutationTypes.SET_EDITABLE_REQUIREMENT,
			setUpdateDebtorRequestCompanyId: mutationTypes.SET_UPDATE_DEBTOR_REQUEST_COMPANY_ID,
			setUpdateDebtorRequestRoleInProject: mutationTypes.SET_UPDATE_DEBTOR_REQUEST_ROLE_IN_PROJECT,
			setUpdateDebtorRequestObligedCompanyId: mutationTypes.SET_UPDATE_DEBTOR_REQUEST_OBLIGED_COMPANY_ID
		}),
		getStatusHistoryDialogItemText(statusItem) {
			const name = this.debtorStatusTypes.find(y => y.id === statusItem?.statusTypeId)?.name;
			const startAtDate = formatDate(statusItem?.startDate, dateFormat);
			const expirationDate = statusItem?.expirationDate ? formatDate(statusItem?.expirationDate, dateFormat) : null;

			const defaultText = `${name}, ${this.$t("common.with")} ${startAtDate}`;

			return expirationDate ? `${defaultText} ${this.$t("common.to")} ${expirationDate}` : defaultText;
		},
		getStatusItemText(statusItem) {
			const name = this.debtorStatusTypes.find(y => y.id === statusItem?.statusTypeId)?.name;
			const updatedAtDate = formatDate(statusItem?.updatedAt, dateWithSecondsFormat);
			const startAtDate = formatDate(statusItem?.startDate, dateFormat);

			return `${name} ${this.$t("common.with")} ${startAtDate} (${updatedAtDate})`;
		},
		sortItemsByDate(items, keyName, order = "desc") {
			return orderBy(items, [(x) => new Date(x[keyName])], [order]);
		},
		getRowJudicialDecisionClass(id) {
			const type = this.judicialDecisionTypes.find(x => x.id === id)?.name;
			switch (type) {
				case JudicialDecisionType.IN_FAVOR_OFFICE:
					return "in-favor-office";
				case JudicialDecisionType.NOT_FAVOR_OFFICE:
					return "not-favor-office";
				case JudicialDecisionType.ENTRY_EXPECTED:
					return "entry-expected";
				default:
					return "";
			}
		},
		handleOpenDeleteItemDialog(mode, item) {
			this.itemToDelete = item;
			this.deleteItemDialogType = mode;
			this.isDeleteItemDialogOpened = true;
		},
		handleOpenEventDialog(mode, type, event) {
			if(event)
				this.setEditableEvent(event);
			this.editableItemModeType = mode;
			this.eventDialogType = type;
			this.isEventDialogOpened = true;

			const filterStatuses = this.sortedStatusHistory.filter(x => this.internalFilterValues.statusHistoryIds.includes(x.statusId));

			if(mode === ProductionStatusEditableItemModeType.CREATE) {
				this.setEditableEventDebtorStatusHistoryItemId(first(filterStatuses)?.statusId);
				this.setEditableDebtorStatus(first(filterStatuses));
			}
			if(mode === ProductionStatusEditableItemModeType.UPDATE) {
				const status = filterStatuses.find(x => x.statusId === this.editableEvent.debtorStatusHistoryItemId);

				this.setEditableEventDebtorStatusHistoryItemId(status?.statusId || "");
				this.setEditableDebtorStatus(status || new DebtorStatus());
			}
		},
		handleOpenRequirementDialog(mode, requirement) {
			if(requirement)
				this.setEditableRequirement(requirement);
			this.editableItemModeType = mode;
			this.isRequirementDialogOpened = true;
		},
		handleDeleteItem() {
			switch (this.deleteItemDialogType) {
				case ProductionStatusDeleteItemDialogType.DEBTOR:
					this.deleteDebtor(this.debtor);
					break;
				case ProductionStatusDeleteItemDialogType.DEBTOR_STATUS:
					this.deleteDebtorStatusHistoryItem({ debtorId: this.debtor.debtorId, statusId: this.itemToDelete.statusId });
					break;
				case ProductionStatusDeleteItemDialogType.EVENT:
				case ProductionStatusDeleteItemDialogType.GOAL:
					this.deleteEvent({ debtorId: this.debtor.debtorId, eventId: this.itemToDelete.eventId });
					break;
				case ProductionStatusDeleteItemDialogType.REQUIREMENT:
					this.deleteRequirement({ debtorId: this.debtor.debtorId, requirementId: this.itemToDelete.requirementId });
					break;
				case ProductionStatusDeleteItemDialogType.REQUIREMENT_HISTORY_ITEM:
					this.deleteDebtorRequirementHistoryItem({
						debtorId: this.debtor.debtorId,
						requirementId: this.itemToDelete.debtorRequirementId,
						historyItemId: this.itemToDelete.id
					});
					break;
			}

			this.deleteItemDialogType = ProductionStatusDeleteItemDialogType.UNKNOWN;
			this.isDeleteItemDialogOpened = false;
		},
		async handleUpdateDebtorStatusBtnClick() {
			await this.updateDebtorStatus({ versionId: this.debtor.versionId, debtorId: this.debtor.debtorId });
			await this.handleResetDebtorStatusDialog();
		},
		async handleResetDebtorStatusDialog() {
			await this.resetEditableDebtorStatus();
			this.$refs.debtorStatusForm.resetValidation();
			this.isDebtorStatusDialogOpened = false;
		},
		async handleUpdateDebtorStatusCommentBtnClick() {
			await this.updateDebtorStatusComment(this.debtor.debtorId);

			this.isDebtorStatusCommentDialogOpened = false;
		},
		async handleEventBtnClick() {
			if(this.editableItemModeType === ProductionStatusEditableItemModeType.CREATE)
				await this.createEvent(this.debtor.debtorId);
			if(this.editableItemModeType === ProductionStatusEditableItemModeType.UPDATE)
				await this.updateEvent(this.debtor.debtorId);

			if(!this.internalFilterValues.statusHistoryIds.includes(this.editableEvent.debtorStatusHistoryItemId))
				this.internalFilterValues.statusHistoryIds.push(this.editableEvent.debtorStatusHistoryItemId);

			this.editableItemModeType = ProductionStatusEditableItemModeType.UNKNOWN;
			this.eventDialogType = ProductionStatusEventDialogType.UNKNOWN;
			this.isEventDialogOpened = false;
		},
		handleCloseEventDialog() {
			this.resetEditableDebtorStatus();
			this.$refs.eventForm.resetValidation();
			this.isEventDialogOpened = false;
		},
		handleRequirementBtnClick() {
			switch (this.editableItemModeType) {
				case ProductionStatusEditableItemModeType.CREATE:
					this.createRequirement(this.debtor.debtorId);
					break;
				case ProductionStatusEditableItemModeType.UPDATE:
					this.updateRequirement(this.debtor.debtorId);
					break;
			}

			this.editableItemModeType = ProductionStatusEditableItemModeType.UNKNOWN;
			this.isRequirementDialogOpened = false;
		},
		async handleOpenDebtorStatusDialog() {
			if(this.debtor.currentStatus)
				this.setEditableDebtorStatus(this.debtor.currentStatus);

			this.setStateSnapshot(ProductionStatusSnapshotKeys.DEBTOR_STATUS);

			this.isDebtorStatusDialogOpened = true;
		},
		handleOpenDebtorStatusCommentDialog() {
			this.setEditableDebtorStatusComment(this.debtor.comment);
			this.setStateSnapshot(ProductionStatusSnapshotKeys.DEBTOR_STATUS_COMMENT);

			this.isDebtorStatusCommentDialogOpened = true;
		},
		async handleOpenDebtorRequirementHistoryDialog(requirementId) {
			await this.fetchDebtorRequirementHistoryItems({ debtorId: this.debtor.debtorId, requirementId });

			this.isDebtorRequirementHistoryDialogOpened = true;
		},
		async handleDebtorBtnClick() {
			await this.updateDebtor(this.debtor.debtorId);

			this.isUpdateDebtorDialogOpened = false;
		},
		async getOrganizations(query, obligedCompany = false) {
			if(query?.length)
				requestBatchService.push(async () => {
					if(obligedCompany)
						await this.fetchObligedCompanies(query);
					else
						await this.fetchOrganizations(query);
				});
		},
		async handleOpenUpdateDebtorDialog() {
			this.setUpdateDebtorRequestCompanyId(this.debtor.debtorCompanyId);
			this.setUpdateDebtorRequestRoleInProject(this.debtor.projectRoleId);
			this.setUpdateDebtorRequestObligedCompanyId(this.debtor.obligedCompanyId);

			this.setStateSnapshot(ProductionStatusSnapshotKeys.UPDATE_DEBTOR_REQUEST);

			this.isUpdateDebtorDialogOpened = true;

			const tasks = [this.fetchOrganizations(this.debtor.debtorCompanyName)];

			if(this.debtor.obligedCompanyId)
				tasks.push(this.fetchObligedCompanies(this.debtor.obligedCompanyName));

			await Promise.all(tasks);
		}
	},
	watch: {
		isEventDialogOpened(value) {
			if(!value) {
				this.setEditableEvent(new DebtorEvent());
				this.$refs.eventForm.resetValidation();
			}
		},
		isRequirementDialogOpened(value) {
			if(!value) {
				this.setEditableRequirement(new ApiRequirement());
				this.$refs.requirementForm.resetValidation();
			}
		},
		sortedStatusHistory: {
			handler(value) {
				if(value)
					this.internalFilterValues.statusHistoryIds = [this.debtor.currentStatus?.statusId];
			}
		},
		"internalFilterValues.statusHistoryIds": {
			handler() {
				if(!this.internalFilterValues.statusHistoryIds?.length)
					this.internalFilterValues.statusHistoryIds = [this.debtor.currentStatus?.statusId];
			}
		}
	},
	components: {
		FrpFilter,
		FrpTextarea,
		FrpTextField,
		FrpAutocomplete,
		FrpDateField,
		FrpDialog,
		FrpIcon,
		FrpBtn,
		FrpTextBodyTwo
	}
};
</script>
<style lang="scss">
.in-favor-office {
	background: var(--v-green-lighten1) !important;
}

.not-favor-office {
	background: var(--v-secondary-lighten1) !important;
}

.entry-expected {
	background: var(--v-warning-lighten1) !important;
}

.loan-scroll-table {
	.v-data-table__wrapper {
		height: auto;
		max-height: 770px;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 6px !important;
		}

		&::-webkit-scrollbar-track {
			background-color: var(--v-blue-base) !important;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--v-blue-lighten4) !important;
		}
	}
}
</style>
