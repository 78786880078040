export default class ApiSecurityAgreement {
	versionId: string;
	securityAgreementId: number;
	securityAgreementProjectId: number;
	securityKindId: number;
	propertyLocation: string;
	guarantor: string;
	agreementValue: number;
	lastAssessmentDate: string;
	assessmentTypeId: string;
	lastValuation: number;
	isPropertySold: boolean;

	constructor(
		versionId: string = "",
		securityAgreementId: number = 0,
		securityAgreementProjectId: number = 0,
		securityKindId: number = 0,
		propertyLocation: string = "",
		guarantor: string = "",
		agreementValue: number = 0,
		lastAssessmentDate: string = "",
		assessmentTypeId: string = "",
		lastValuation: number = 0,
		isPropertySold: boolean = false
	)
	{
		this.versionId = versionId;
		this.securityAgreementId = securityAgreementId;
		this.securityAgreementProjectId = securityAgreementProjectId;
		this.securityKindId = securityKindId;
		this.propertyLocation = propertyLocation;
		this.guarantor = guarantor;
		this.agreementValue = agreementValue;
		this.lastAssessmentDate = lastAssessmentDate;
		this.assessmentTypeId = assessmentTypeId;
		this.lastValuation = lastValuation;
		this.isPropertySold = isPropertySold;
	}
}
