<template>
	<div v-if="isInitialized && !isVersionLoading">
		<v-row>
			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-7 fill-height">
					<v-row>
						<v-col>
							<div class="text-h6 blue--text text--darken-4 d-flex">
								<span class="mr-2 text-no-wrap">{{ $t("titles.loanAgreements") }}:</span>
								<div class="d-flex flex-column">
									<span v-for="(agreement, index) in version.loanAgreements.slice(0,3)"
										  :key="`${agreement.number}-${index}`">
										<span>
											<a :href="`/projects/project/${agreement.projectId}`"
											   class="loan-link">
												№ {{ agreement.name }}
											</a>
										</span>
										<template v-if="agreement.status">
											<span> - {{ agreement.status }}</span>
										</template>
										<span v-if="index !== version.loanAgreements.length - 1 && index !== 2">,</span>
									</span>
								</div>
								<frp-btn v-if="version.loanAgreements.length > 3"
										 plain
										 dense
										 text
										 :color="colors.blue.base"
										 height="23"
										 style="padding: 0 !important;"
										 class="text-none font-weight-regular align-start"
										 @click="isLoanAgreementsDialogOpened = true">
									{{ `${$t("buttons.more")} ${version.loanAgreements.length - 3}` }}
								</frp-btn>
							</div>
						</v-col>
					</v-row>
					
					<v-row class="mx-0">
						<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
							<div>{{ $t("details.titles.financingScheme") }}</div>
							<div>{{ $t("details.titles.loanAgreementsFrpSum") }}</div>
							<div>{{ $t("details.titles.loanAgreementsRequirements") }}</div>
							<div class="pl-12">{{ $t("details.titles.loanAgreementsMainDebt") }}</div>
							<div class="pl-12">{{ $t("details.titles.loanAgreementsInterest") }}</div>
							<div class="pl-12">{{ $t("details.titles.loanAgreementsPenalty") }}</div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div v-for="i in 6" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div>{{ version.financingSchemes.map(x => x.name).join(", ") }}</div>
							<div>{{ formatCurrency(version.frpSum, 1) }}</div>
							<div>{{ formatCurrency(loanAgreementsRequirements, 1) }}</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ formatCurrency(version.mainDebt, 1) }}</span>
								<frp-text-field dense
												style="max-width: 100px"
												v-model.number="mainDebt"
												type="number"
												hide-details
												v-else>
								</frp-text-field>
							</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ formatCurrency(version.interest, 1) }}</span>
								<frp-text-field dense
												style="max-width: 100px"
												v-model.number="interest"
												type="number"
												hide-details
												v-else>
								</frp-text-field>
							</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ formatCurrency(version.penalty, 1) }}</span>
								<frp-text-field dense
												style="max-width: 100px"
												v-model.number="penalty"
												type="number"
												hide-details
												v-else>
								</frp-text-field>
							</div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-7 fill-height">
					<div class="text-h6 blue--text text--darken-4 mb-1">
						{{ $t("details.titles.refund") }}
					</div>
					<v-row class="mx-0 mt-3">
						<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
							<div>{{ $t("details.titles.refundStatus") }}</div>
							<div>{{ $t("details.titles.partialRefund") }}</div>
							<div class="pl-12">{{ $t("details.titles.refundWithoutAcceptance") }}</div>
							<div class="pl-12">{{ $t("details.titles.refundBankGuarantee") }}</div>
							<div class="pl-12">{{ $t("details.titles.refundPenalty") }}</div>
							<div>{{ $t("details.titles.remainingRefund") }}</div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div v-for="i in 6" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div class="d-flex justify-space-between align-center">
								<div class="d-flex flex-column justify-center">
									<span v-if="mode === MainInfoModeType.READ">
										{{ refundStatusTypes.find(x => x.id === version.refund.refundStatusTypeId)?.name }}
									</span>
									<frp-autocomplete dense
													  required
													  :items="refundStatusTypes"
													  item-text="name"
													  item-value="id"
													  v-model="refundStatus"
													  hide-details
													  v-else>
									</frp-autocomplete>
								</div>
								<frp-btn small
										 @click="handleOpenRefundStatusTypeHistoryDialog()"
										 class="mr-1"
										 icon>
									<frp-icon src="ico_history" :color="colors.blue.darken4"></frp-icon>
								</frp-btn>
							</div>
							<div>{{ formatCurrency(partialRefund, 1) }}</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ formatCurrency(version.refund.withoutAcceptance, 1) }}</span>
								<frp-text-field dense
												style="max-width: 100px"
												v-model.number="refundWithoutAcceptance"
												type="number"
												hide-details
												v-else>
								</frp-text-field>
							</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ formatCurrency(version.refund.bankGuarantee, 1) }}</span>
								<frp-text-field dense
												style="max-width: 100px"
												v-model.number="refundBankGuarantee"
												type="number"
												hide-details
												v-else>
								</frp-text-field>
							</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ formatCurrency(version.refund.penalty, 1) }}</span>
								<frp-text-field dense
												style="max-width: 100px"
												v-model.number="refundPenalty"
												type="number"
												hide-details
												v-else>
								</frp-text-field>
							</div>
							<div>{{ formatCurrency(remainingRefund, 1) }}</div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<v-row class="mt-3">
			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-8 fill-height">
					<span class="text-h6 blue--text text--darken-4">
						{{ $t("details.titles.keyDates") }}
					</span>
					<v-row class="mx-0 mt-3">
						<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
							<div>{{ $t("details.titles.keyDatesRecall") }}</div>
							<div>{{ $t("details.titles.keyDatesRequirements") }}</div>
							<div>{{ $t("details.titles.keyDatesDeclaration") }}</div>
							<div>{{ $t("details.titles.keyDatesSettlement") }}</div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ version.keyDates.recallReason }}</span>
								<frp-text-field dense
												v-else
												style="max-width: 100px"
												v-model="keyDatesRecallReason"
												hide-details>
								</frp-text-field>
							</div>
							<div v-for="i in 3" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ formatDate(version.keyDates.recallDate, dateFormat) }}</span>
								<frp-date-field dense
												v-else
												placeholder=""
												width="130px"
												v-model="keyDatesRecallDate"
												hide-details>
								</frp-date-field>
							</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">
									{{ formatDate(version.keyDates.requirements, dateFormat) }}
								</span>
								<frp-date-field dense
												placeholder=""
												width="130px"
												v-model.number="keyDatesRequirements"
												hide-details
												v-else>
								</frp-date-field>
							</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ version.keyDates.declaration }}</span>
								<frp-text-field dense
												placeholder=""
												width="130px"
												v-model="keyDatesDeclaration"
												hide-details
												v-else>
								</frp-text-field>
							</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">{{ formatDate(version.keyDates.settlement, dateFormat) }}</span>
								<frp-date-field dense
												placeholder=""
												width="130px"
												v-model.number="keyDatesSettlement"
												hide-details
												v-else>
								</frp-date-field>
							</div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-8 fill-height">
					<span class="text-h6 blue--text text--darken-4">
						{{ $t("details.titles.responsibleUsers") }}
					</span>
					<v-row cols="5" class="mx-0 mt-3">
						<v-col class="pa-0 main-block-column main-block-column-left">
							<div>{{ $t("details.titles.problemDebtDepartmentUser") }}</div>
							<div>{{ $t("details.titles.problemDebtDepartmentOrProjectManagerUser") }}</div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div v-for="i in 2" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div>
								<span v-if="mode === MainInfoModeType.READ">
									{{ problemDebtDepartments.find(x => x.id === version.responsibleUsersInfo.legalDepartmentUserId)?.name }}
								</span>
								<frp-autocomplete dense
												  :items="problemDebtDepartments"
												  item-text="name"
												  item-value="id"
												  v-model="legalDepartmentUserId"
												  hide-details
												  v-else>
								</frp-autocomplete>
							</div>
							<div>
								<span v-if="mode === MainInfoModeType.READ">
									{{ combinedProblemDebtDepartmentAndProjectManagerUsers.find(x => x.id === version.responsibleUsersInfo.projectManagerUserId)?.name }}
								</span>
								<frp-autocomplete dense
												  :items="combinedProblemDebtDepartmentAndProjectManagerUsers"
												  item-text="name"
												  item-value="id"
												  v-model="projectManagerUserId"
												  hide-details
												  v-else>
								</frp-autocomplete>
							</div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<v-row class="mt-3">
			<v-col>
				<refund-sources></refund-sources>
			</v-col>
			<v-col>
				<income-predictions></income-predictions>
			</v-col>
		</v-row>
		<v-row class="mt-3">
			<v-col>
				<loan-costs></loan-costs>
			</v-col>
			<v-col>
			</v-col>
		</v-row>
		<v-row class="mb-0">
			<v-col class="d-flex justify-end">
				<frp-btn v-if="mode === MainInfoModeType.READ && can(Permissions.LOAN_UPDATE)"
						 :color="colors.blue.base"
						 elevation="0"
						 @click="setMode(MainInfoModeType.EDIT)">
					<span class="white--text">{{ $t("buttons.edit") }}</span>
				</frp-btn>
				<frp-btn v-if="mode === MainInfoModeType.EDIT"
						 :color="colors.blue.base"
						 :loading="isFormSaving"
						 elevation="0"
						 @click="save">
					<span class="white--text">{{ $t("buttons.save") }}</span>
				</frp-btn>
				
				<v-fade-transition>
					<v-alert v-if="stateContainsUnsavedChanges && mode === MainInfoModeType.EDIT"
							 class="pa-4 text-start loan-card"
							 style="position: absolute; bottom: 53px; right: 24px; box-shadow: 0 8px 7px rgba(0, 0, 0, 0.08)">
						<div class="d-flex flex-column text-none">
							<span class="subtitle-2 primary--text font-weight-regular">{{ $t("alerts.saveChangesAlert.title") }}</span>
							<span class="text-body-2 grey--text mt-2">{{ $t("alerts.saveChangesAlert.text") }}</span>
						</div>
					</v-alert>
				</v-fade-transition>
			</v-col>
		</v-row>
		
		<frp-dialog v-model="isLoanAgreementsDialogOpened"
					max-width="568"
					persistent
					:title="$t(`dialogs.loanAgreements.title`)">
			<template #content>
				<div class="d-flex flex-column text-subtitle-1 blue--text text--darken-4" style="row-gap: 24px">
					<span v-for="(agreement, index) in version.loanAgreements" :key="`${agreement.number}-${index}`">
						<span>
							<a :href="`/projects/project/${agreement.projectId}`"
							   class="loan-link">
								№ {{ agreement.name }}
							</a>
						</span>
						<span> - {{ agreement.status }}</span>
					</span>
				</div>
			</template>
			
			<template #footer>
				<frp-btn :color="colors.blue.base"
						 elevation="0"
						 @click="isLoanAgreementsDialogOpened = false">
					<span class="white--text">{{ $t("buttons.close") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
		
		<frp-dialog max-width="568"
					v-model="isRefundStatusTypeHistoryDialogOpened"
					:title="$t('dialogs.refundStatusTypeHistory.title')">
			<template #content="{ onIntersect }">
				<div class="d-flex justify-center flex-column" style="gap: 8px" v-intersect="onIntersect">
					<frp-text-body-two v-if="!refundStatusTypeHistory?.length"
									   class="grey--text align-self-center mt-6 mb-3">
						{{ $t("dialogs.debtorStatusHistory.noDataText") }}
					</frp-text-body-two>
					<div class="d-flex justify-space-between align-center" v-else v-for="(item, i) in refundStatusTypeHistory" :key="i">
						<div class="d-flex flex-column justify-center">
							<span class="text-subtitle-2 primary--text">
								{{ refundStatusTypeName(item.refundStatusTypeId) || "-" }}
							</span>
							<span class="text-caption grey--text mt-1">
								{{ item.createdBy }}, {{ formatDate(item.createdAt, dateTimeFormat) }}
							</span>
						</div>
					</div>
				</div>
			</template>
			
			<template #footer>
				<frp-btn class="elevation-0"
						 @click="isRefundStatusTypeHistoryDialogOpened = false"
						 :color="colors.blue.base">
					<span class="white--text">{{ $t("buttons.close") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
	
	</div>
	<loan-main-info-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextField from "Components/fields/FrpTextField";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo";
import FrpIcon from "@/components/icon/FrpIcon";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { MIN_USERS_QUERY_LENGTH } from "Store/loan/modules/troubledBorrower/constants";
import { MainInfoModeType } from "Store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
import { formatDate } from "Utils/dates";
import { dateFormat, dateTimeFormat } from "Utils/formats";
import { formatCurrency } from "Utils/formatting";
import { roundDownToDecimalPlace } from "Utils/number";
import LoanMainInfoLoader from "Views/loan/troubledBorrower/mainInfo/LoanMainInfoLoader";
import RefundSources from "@/views/loan/troubledBorrower/mainInfo/RefundSources";
import IncomePredictions from "@/views/loan/troubledBorrower/mainInfo/IncomePredictions";
import LoanCosts from "@/views/loan/troubledBorrower/mainInfo/LoanCosts";
import {
	getterTypes,
	mutationTypes,
	actionTypes
} from "Store/loan/modules/troubledBorrower/modules/mainInfo/types";
import { actionTypes as troubledBorrowerActionTypes } from "@/store/loan/modules/troubledBorrower/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";
import authorizationMixin from "@/mixins/authorizationMixin";

const namespace = storeManager.loan.troubledBorrower.mainInfo.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const { mapState: troubledBorrowerMapState, mapActions: troubledBorrowerMapActions, mapMutations: troubledBorrowerMapMutation } = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

export default {
	mixins: [formMixin, storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			MainInfoModeType,
			formatCurrency,
			dateFormat,
			dateTimeFormat,
			formatDate,
			MIN_USERS_QUERY_LENGTH,
			isLoanAgreementsDialogOpened: false,
			isRefundStatusTypeHistoryDialogOpened: false
		};
	},
	computed: {
		...troubledBorrowerMapState({
			projectManagers: state => state.projectManagers,
			problemDebtDepartments: state => state.problemDebtDepartments,
			refundStatusTypes: state => state.refundStatusTypes,
			isVersionLoading: state => state.isVersionLoading,
			refundStatusTypeHistory: state => state.refundStatusTypeHistory
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			editableVersion: state => state.editableVersion,
			mode: state => state.mode
		}),
		...mapGetters({
			version: getterTypes.version
		}),
		mainDebt: {
			get() {
				return this.editableVersion.mainDebt;
			},
			set(value) {
				this.setMainDebt(roundDownToDecimalPlace(value));
			}
		},
		interest: {
			get() {
				return this.editableVersion.interest;
			},
			set(value) {
				this.setInterest(roundDownToDecimalPlace(value));
			}
		},
		penalty: {
			get() {
				return this.editableVersion.penalty;
			},
			set(value) {
				this.setPenalty(roundDownToDecimalPlace(value));
			}
		},
		refundStatus: {
			get() {
				return this.editableVersion.refund.refundStatusTypeId;
			},
			set(value) {
				this.setRefundStatus(value);
			}
		},
		refundWithoutAcceptance: {
			get() {
				return this.editableVersion.refund.withoutAcceptance;
			},
			set(value) {
				this.setRefundWithoutAcceptance(roundDownToDecimalPlace(value));
			}
		},
		refundBankGuarantee: {
			get() {
				return this.editableVersion.refund.bankGuarantee;
			},
			set(value) {
				this.setRefundBankGuarantee(roundDownToDecimalPlace(value));
			}
		},
		refundPenalty: {
			get() {
				return this.editableVersion.refund.penalty;
			},
			set(value) {
				this.setRefundPenalty(roundDownToDecimalPlace(value));
			}
		},
		keyDatesRecallReason: {
			get() {
				return this.editableVersion.keyDates.recallReason;
			},
			set(value) {
				this.setKeyDatesRecallReason(value);
			}
		},
		keyDatesRecallDate: {
			get() {
				return this.editableVersion.keyDates.recallDate;
			},
			set(value) {
				this.setKeyDatesRecallDate(value);
			}
		},
		keyDatesRequirements: {
			get() {
				return this.editableVersion.keyDates.requirements;
			},
			set(value) {
				this.setKeyDatesRequirements(value);
			}
		},
		keyDatesDeclaration: {
			get() {
				return this.editableVersion.keyDates.declaration;
			},
			set(value) {
				this.setKeyDatesDeclaration(value);
			}
		},
		keyDatesSettlement: {
			get() {
				return this.editableVersion.keyDates.settlement;
			},
			set(value) {
				this.setKeyDatesSettlement(value);
			}
		},
		legalDepartmentUserId: {
			get() {
				return this.editableVersion.responsibleUsersInfo.legalDepartmentUserId;
			},
			set(value) {
				this.setLegalDepartmentUserId(value);
			}
		},
		projectManagerUserId: {
			get() {
				return this.editableVersion.responsibleUsersInfo.projectManagerUserId;
			},
			set(value) {
				this.setProjectManagerUserId(value);
			}
		},
		combinedProblemDebtDepartmentAndProjectManagerUsers() {
			const problemDebtDepartmentOrProjectManagerUsersArray = [...this.problemDebtDepartments, ...this.projectManagers];
				
			// удаляем повторяющиеся элементы по id из двух массивов пользователей
			const ids = problemDebtDepartmentOrProjectManagerUsersArray.map(({ id }) => id);
			return problemDebtDepartmentOrProjectManagerUsersArray.filter(({ id }, index) => !ids.includes(id, index + 1));
		},
		partialRefund() {
			const version = this.mode === MainInfoModeType.READ ? this.version : this.editableVersion;
			return +version.refund.withoutAcceptance + +version.refund.bankGuarantee + +version.refund.penalty;
		},
		loanAgreementsRequirements() {
			switch (this.mode) {
				case MainInfoModeType.READ:
					return +this.version.mainDebt + +this.version.interest + +this.version.penalty;
				
				case MainInfoModeType.EDIT:
					return +this.editableVersion.mainDebt + +this.editableVersion.interest + +this.editableVersion.penalty;
			}
		},
		remainingRefund() {
			return this.loanAgreementsRequirements - this.partialRefund;
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			save: actionTypes.save
		}),
		...troubledBorrowerMapActions({
			fetchRefundStatusTypeHistory: troubledBorrowerActionTypes.fetchRefundStatusTypeHistory
		}),
		...mapMutations({
			setMode: mutationTypes.SET_MODE,
			setMainDebt: mutationTypes.SET_MAIN_DEBT,
			setInterest: mutationTypes.SET_INTEREST,
			setPenalty: mutationTypes.SET_PENALTY,
			setRefundStatus: mutationTypes.SET_REFUND_STATUS_TYPE_ID,
			setRefundWithoutAcceptance: mutationTypes.SET_REFUND_WITHOUT_ACCEPTANCE,
			setRefundBankGuarantee: mutationTypes.SET_REFUND_BANK_GUARANTEE,
			setRefundPenalty: mutationTypes.SET_REFUND_PENALTY,
			setKeyDatesRecallReason: mutationTypes.SET_KEY_DATES_RECALL_REASON,
			setKeyDatesRecallDate: mutationTypes.SET_KEY_DATES_RECALL_DATE,
			setKeyDatesRequirements: mutationTypes.SET_KEY_DATES_REQUIREMENTS,
			setKeyDatesDeclaration: mutationTypes.SET_KEY_DATES_DECLARATION,
			setKeyDatesSettlement: mutationTypes.SET_KEY_DATES_SETTLEMENT,
			setLegalDepartmentUserId: mutationTypes.SET_LEGAL_DEPARTMENT_USER_ID,
			setProjectManagerUserId: mutationTypes.SET_PROJECT_MANAGER_USER_ID
		}),
		async handleOpenRefundStatusTypeHistoryDialog() {
			await this.fetchRefundStatusTypeHistory(this.version.versionHeader.versionId);
			this.isRefundStatusTypeHistoryDialogOpened = true;
		},
		refundStatusTypeName(refundStatusTypeId) {
			if(!refundStatusTypeId)
				return "";
			return this.refundStatusTypes.find(x => x.id == refundStatusTypeId)?.name;
		}
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		FrpDialog,
		FrpTextBodyTwo,
		FrpDateField,
		FrpAutocomplete,
		FrpTextField,
		FrpBtn,
		FrpIcon,
		LoanMainInfoLoader,
		RefundSources,
		IncomePredictions,
		LoanCosts,
	}
};
</script>

<style scoped lang="scss">
.main-block-column {
  & > div {
	height: 48px;
	display: flex;
	align-items: center;
  }

  & > div:nth-child(even) {
	background-color: var(--v-primary-lighten4);
  }
}

.main-block-column-left {
  & > div {
	padding-left: 16px;
  }
}

.main-block-column-right {
  & > div {
	padding-right: 16px;
  }
}
</style>
