import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiSecurityAgreement from "@/api/loan/types/troubledBorrower/apiSecurityAgreement";
import SecurityAgreement from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types/securityAgreement";
import { convertToZonedIso, convertToTimestamp } from "@/utils/dates";

const mapper = createMapper({
	strategyInitializer: classes()
});

const securityAgreementsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiSecurityAgreement, SecurityAgreement,
		forMember(d => d.versionId, mapFrom(x => x.versionId)),
		forMember(d => d.securityAgreementId, mapFrom(x => x.securityAgreementId)),
		forMember(d => d.securityAgreementProjectId, mapFrom(x => x.securityAgreementProjectId)),
		forMember(d => d.securityKindId, mapFrom(x => x.securityKindId)),
		forMember(d => d.propertyLocation, mapFrom(x => x.propertyLocation)),
		forMember(d => d.guarantor, mapFrom(x => x.guarantor)),
		forMember(d => d.agreementValue, mapFrom(x => x.agreementValue)),
		forMember(d => d.lastAssessmentDate, mapFrom(x => convertToTimestamp(x.lastAssessmentDate))),
		forMember(d => d.assessmentTypeId, mapFrom(x => x.assessmentTypeId)),
		forMember(d => d.lastValuation, mapFrom(x => x.lastValuation)),
		forMember(d => d.isPropertySold, mapFrom(x => x.isPropertySold))
	);

	createMap(mapper, SecurityAgreement, ApiSecurityAgreement,
		forMember(d => d.versionId, mapFrom(x => x.versionId)),
		forMember(d => d.securityAgreementId, mapFrom(x => x.securityAgreementId)),
		forMember(d => d.securityAgreementProjectId, mapFrom(x => x.securityAgreementProjectId)),
		forMember(d => d.securityKindId, mapFrom(x => x.securityKindId)),
		forMember(d => d.propertyLocation, mapFrom(x => x.propertyLocation)),
		forMember(d => d.guarantor, mapFrom(x => x.guarantor)),
		forMember(d => d.agreementValue, mapFrom(x => x.agreementValue)),
		forMember(d => d.lastAssessmentDate, mapFrom(x => convertToZonedIso(x.lastAssessmentDate))),
		forMember(d => d.assessmentTypeId, mapFrom(x => x.assessmentTypeId)),
		forMember(d => d.lastValuation, mapFrom(x => x.lastValuation || 0)),
		forMember(d => d.isPropertySold, mapFrom(x => x.isPropertySold))
	);
};

addProfile(mapper, securityAgreementsProfile);

export default mapper;
