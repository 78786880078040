import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp } from "@/utils/dates";
import ApiTroubledBorrowerVersion from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersion";
import TroubledBorrowerVersion from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerVersion";
import ApiKeyDates from "@/api/loan/types/troubledBorrower/apiKeyDates";
import KeyDates from "@/store/loan/modules/troubledBorrower/types/keyDates";
import ApiRefund from "@/api/loan/types/troubledBorrower/apiRefund";
import Refund from "@/store/loan/modules/troubledBorrower/types/refund";
import ApiRefundSource from "@/api/loan/types/troubledBorrower/apiRefundSource";
import RefundSource from "@/store/loan/modules/troubledBorrower/types/refundSource";
import ApiRefundSourceHistory from "@/api/loan/types/troubledBorrower/apiRefundSourceHistory";
import RefundSourceHistory from "@/store/loan/modules/troubledBorrower/types/refundSourceHistory";
import { expandDefault } from "@/api/loan/types/troubledBorrower/apiIncomePrediction";
import ApiIncomePredictionHistory from "@/api/loan/types/troubledBorrower/apiIncomePredictionHistory";
import IncomePredictionHistory from "./types/incomePredictionHistory";
import ApiRefundStatusTypeHistory from "@/api/loan/types/troubledBorrower/apiRefundStatusTypeHistory";
import RefundStatusTypeHistory from "./types/refundStatusTypeHistory";

const mapper = createMapper({
	strategyInitializer: classes()
});

const troubledBorrowerProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiTroubledBorrowerVersion, TroubledBorrowerVersion,
		forMember(d => d.versionHeader, mapFrom(x => x.versionHeader)),
		forMember(d => d.loanAgreements, mapFrom(x => x.loanAgreements)),
		forMember(d => d.frpSum, mapFrom(x => x.frpSum)),
		forMember(d => d.mainDebt, mapFrom(x => x.mainDebt || 0)),
		forMember(d => d.interest, mapFrom(x => x.interest || 0)),
		forMember(d => d.penalty, mapFrom(x => x.penalty || 0)),
		forMember(d => d.financingSchemes, mapFrom(x => x.financingSchemes)),
		forMember(d => d.refund, mapFrom(x => mapper.map(x.refund, ApiRefund, Refund))),
		forMember(d => d.keyDates, mapFrom(x => mapper.map(x.keyDates, ApiKeyDates, KeyDates))),
		forMember(d => d.responsibleUsersInfo, mapFrom(x => x.responsibleUsersInfo)),
		forMember(d => d.refundSourceInfo, mapFrom(x => mapper.map(x.refundSourceInfo, ApiRefundSource, RefundSource))),
		forMember(d => d.incomePredictions, mapFrom(x => expandDefault(x.incomePredictions))),
		forMember(d => d.costs, mapFrom(x => x.costs))
	);

	createMap(mapper, TroubledBorrowerVersion, ApiTroubledBorrowerVersion,
		forMember(d => d.versionHeader, mapFrom(x => x.versionHeader)),
		forMember(d => d.loanAgreements, mapFrom(x => x.loanAgreements)),
		forMember(d => d.frpSum, mapFrom(x => x.frpSum)),
		forMember(d => d.mainDebt, mapFrom(x => x.mainDebt)),
		forMember(d => d.interest, mapFrom(x => x.interest)),
		forMember(d => d.penalty, mapFrom(x => x.penalty)),
		forMember(d => d.financingSchemes, mapFrom(x => x.financingSchemes)),
		forMember(d => d.refund, mapFrom(x => mapper.map(x.refund, Refund, ApiRefund))),
		forMember(d => d.keyDates, mapFrom(x => mapper.map(x.keyDates, KeyDates, ApiKeyDates))),
		forMember(d => d.responsibleUsersInfo, mapFrom(x => x.responsibleUsersInfo)),
		forMember(d => d.refundSourceInfo, mapFrom(x => mapper.map(x.refundSourceInfo, RefundSource, ApiRefundSource))),
		forMember(d => d.incomePredictions, mapFrom(x => x.incomePredictions)),
		forMember(d => d.costs, mapFrom(x => x.costs)),
	);

	createMap(mapper, ApiKeyDates, KeyDates,
		forMember(d => d.recallReason, mapFrom(x => x.recallReason)),
		forMember(d => d.recallDate, mapFrom(x => convertToTimestamp(x.recallDate))),
		forMember(d => d.requirements, mapFrom(x => convertToTimestamp(x.requirements))),
		forMember(d => d.declaration, mapFrom(x => x.declaration)),
		forMember(d => d.settlement, mapFrom(x => convertToTimestamp(x.settlement)))
	);

	createMap(mapper, KeyDates, ApiKeyDates,
		forMember(d => d.recallReason, mapFrom(x => x.recallReason)),
		forMember(d => d.recallDate, mapFrom(x => convertToZonedIso(x.recallDate))),
		forMember(d => d.requirements, mapFrom(x => convertToZonedIso(x.requirements))),
		forMember(d => d.declaration, mapFrom(x => x.declaration)),
		forMember(d => d.settlement, mapFrom(x => convertToZonedIso(x.settlement)))
	);

	createMap(mapper, ApiRefund, Refund,
		forMember(d => d.refundStatusTypeId, mapFrom(x => x.refundStatusTypeId)),
		forMember(d => d.withoutAcceptance, mapFrom(x => x.withoutAcceptance)),
		forMember(d => d.bankGuarantee, mapFrom(x => x.bankGuarantee)),
		forMember(d => d.penalty, mapFrom(x => x.penalty))
	);

	createMap(mapper, Refund, ApiRefund,
		forMember(d => d.refundStatusTypeId, mapFrom(x => x.refundStatusTypeId)),
		forMember(d => d.withoutAcceptance, mapFrom(x => x.withoutAcceptance || 0)),
		forMember(d => d.bankGuarantee, mapFrom(x => x.bankGuarantee || 0)),
		forMember(d => d.penalty, mapFrom(x => x.penalty || 0))
	);

	createMap(mapper, ApiRefundSource, RefundSource,
		forMember(d => d.startDate, mapFrom(x => convertToTimestamp(x.startDate))),
		forMember(d => d.refundSourceType, mapFrom(x => x.refundSourceType))
	);

	createMap(mapper, RefundSource, ApiRefundSource,
		forMember(d => d.startDate, mapFrom(x => convertToZonedIso(x.startDate))),
		forMember(d => d.refundSourceType, mapFrom(x => x.refundSourceType))
	);

	createMap(mapper, ApiRefundSourceHistory, RefundSourceHistory,
		forMember(d => d.createdAt, mapFrom(x => convertToTimestamp(x.createdAt))),
		forMember(d => d.createdBy, mapFrom(x => x.creator.name)),
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.refundSource, mapFrom(x => mapper.map(x.refundSource, ApiRefundSource, RefundSource)))
	);

	createMap(mapper, ApiIncomePredictionHistory, IncomePredictionHistory,
		forMember(d => d.createdAt, mapFrom(x => convertToTimestamp(x.createdAt))),
		forMember(d => d.createdBy, mapFrom(x => x.createdBy.name)),
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.year, mapFrom(x => x.year)),
		forMember(d => d.quarter, mapFrom(x => x.quarter)),
		forMember(d => d.conservativeValue, mapFrom(x => x.conservativeValue)),
		forMember(d => d.optimisticValue, mapFrom(x => x.optimisticValue)),
	);

	createMap(mapper, ApiRefundStatusTypeHistory, RefundStatusTypeHistory,
		forMember(d => d.createdAt, mapFrom(x => convertToTimestamp(x.createdAt))),
		forMember(d => d.createdBy, mapFrom(x => x.createdBy.name)),
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.refundStatusTypeId, mapFrom(x => x.refundStatusTypeId)),
	);

};

addProfile(mapper, troubledBorrowerProfile);

export default mapper;
