import ApiTroubledBorrowerLoanAgreement from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerLoanAgreement";
import ApiTroubledBorrowerFinancingScheme from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerFinancingScheme";
import ApiTroubledBorrowerVersionHeader from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersionHeader";
import ApiRefund from "@/api/loan/types/troubledBorrower/apiRefund";
import ApiKeyDates from "@/api/loan/types/troubledBorrower/apiKeyDates";
import ApiResponsibleUsersInfo from "@/api/loan/types/troubledBorrower/apiResponsibleUsersInfo";
import ApiRefundSource from "@/api/loan/types/troubledBorrower/apiRefundSource";
import ApiIncomePrediction from "@/api/loan/types/troubledBorrower/apiIncomePrediction";
import ApiCost from "@/api/loan/types/troubledBorrower/apiCost";

export default class ApiTroubledBorrowerVersion {
	versionHeader: ApiTroubledBorrowerVersionHeader;
	loanAgreements: ApiTroubledBorrowerLoanAgreement[];
	frpSum: number;
	mainDebt: number;
	interest: number;
	penalty: number;
	financingSchemes: ApiTroubledBorrowerFinancingScheme[];
	refund: ApiRefund;
	keyDates: ApiKeyDates;
	responsibleUsersInfo: ApiResponsibleUsersInfo;
	refundSourceInfo: ApiRefundSource;
	incomePredictions: ApiIncomePrediction[];
	costs: ApiCost[];

	constructor(
		versionHeader: ApiTroubledBorrowerVersionHeader = new ApiTroubledBorrowerVersionHeader(),
		loanAgreements: ApiTroubledBorrowerLoanAgreement[] = [],
		frpSum: number = 0,
		mainDebt: number = 0,
		interest: number = 0,
		penalty: number = 0,
		financingSchemes: ApiTroubledBorrowerFinancingScheme[] = [],
		refund: ApiRefund = new ApiRefund(),
		keyDates: ApiKeyDates = new ApiKeyDates(),
		responsibleUsersInfo: ApiResponsibleUsersInfo = new ApiResponsibleUsersInfo(),
		refundSourceInfo: ApiRefundSource = new ApiRefundSource(),
		incomePredictions: ApiIncomePrediction[] = [],
		costs: ApiCost[] = [],
	)
	{
		this.versionHeader = versionHeader;
		this.loanAgreements = loanAgreements;
		this.frpSum = frpSum;
		this.mainDebt = mainDebt;
		this.interest = interest;
		this.penalty = penalty;
		this.financingSchemes = financingSchemes;
		this.refund = refund;
		this.keyDates = keyDates;
		this.responsibleUsersInfo = responsibleUsersInfo;
		this.refundSourceInfo = refundSourceInfo;
		this.incomePredictions = incomePredictions;
		this.costs = costs;
	}
}
