import ApiRefundSourceType from "@/api/loan/types/dictionaries/apiRefundSourceType";

export default class ApiRefundSource {
	refundSourceType?: ApiRefundSourceType;
	startDate?: string;

	constructor(refundSourceType?: ApiRefundSourceType, startDate?: string) {
		this.refundSourceType = refundSourceType;
		this.startDate = startDate;
	}
}
