export default class ApiDebtorStatusType {
	id: string;
	name: string;
	canBeIndividual: boolean;
	canBeLegalEntity: boolean;

	constructor(
		id: string = "",
		name: string = "",
		canBeIndividual: boolean = false,
		canBeLegalEntity: boolean = false
	)
	{
		this.id = id;
		this.name = name;
		this.canBeIndividual = canBeIndividual;
		this.canBeLegalEntity = canBeLegalEntity;
	}
}
