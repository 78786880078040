import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/troubledBorrower/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import TroubledBorrowerState from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerState";
import AbortService from "@/services/abortService";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/loan/dictionaries";
import { TroubledBorrowerController } from "@/api/loan/troubledBorrower";
import mainInfoModule from "@/store/loan/modules/troubledBorrower/modules/mainInfo";
import provisionModule from "@/store/loan/modules/troubledBorrower/modules/securityAgreements";
import proceedingStatusModule from "@/store/loan/modules/troubledBorrower/modules/productionStatus";
import router from "@/router/loan";
import mapper from "@/store/loan/modules/troubledBorrower/mapper";
import ApiTroubledBorrowerVersion from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersion";
import TroubledBorrowerVersion from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerVersion";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import { resolveAction, resolveMutation } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import mainInfoTypes from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types";
import { cloneDeep } from "lodash";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import { actionTypes as productionStatusActionTypes } from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types";
import { actionTypes as securityAgreementsActionTypes } from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types";
import { convertIsoToNumber } from "@/utils/dates";
import RefundSourceHistory from "./types/refundSourceHistory";
import ApiRefundSourceHistory from "@/api/loan/types/troubledBorrower/apiRefundSourceHistory";
import ApiIncomePredictionHistory from "@/api/loan/types/troubledBorrower/apiIncomePredictionHistory";
import IncomePredictionHistory from "./types/incomePredictionHistory";
import ApiRefundStatusTypeHistory from "@/api/loan/types/troubledBorrower/apiRefundStatusTypeHistory";
import RefundStatusTypeHistory from "./types/refundStatusTypeHistory";

const abortService = new AbortService();
const troubledBorrowerController = new TroubledBorrowerController(abortService);
const dictionariesController = new DictionariesController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["versionName"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new TroubledBorrowerState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<TroubledBorrowerState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
};

const actions = <ActionTree<TroubledBorrowerState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetchCompany);
		await dispatch(actionTypes.fetchVersionHeaders);
		await dispatch(actionTypes.fetchDictionaries);
		await dispatch(actionTypes.fetchVersion);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchVersionHeaders]({ commit, state }) {
		commit(mutationTypes.SET_IS_VERSION_HEADERS_LOADING, true);

		try {
			const headers = await troubledBorrowerController.getVersionHeaders(state.company.id);

			commit(mutationTypes.SET_VERSION_HEADERS, headers);
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_VERSION_HEADERS_LOADING, false);
		}
	},
	async [actionTypes.fetchVersion]({ commit, state, dispatch }, id) {
		commit(mutationTypes.SET_IS_VERSION_LOADING, true);

		try {
			if(id) {
				const version = await troubledBorrowerController.getVersion(id);

				commit(mutationTypes.SET_VERSION, mapper.map(version, ApiTroubledBorrowerVersion, TroubledBorrowerVersion));

				await dispatch(resolveAction(storeManager.loan.troubledBorrower.securityAgreements.namespace,
					securityAgreementsActionTypes.updateListingItems), {}, { root: true });

				await dispatch(resolveAction(storeManager.loan.troubledBorrower.productionStatus.namespace,
					productionStatusActionTypes.updateListingItems), {}, { root: true });
			} else {
				if(state.versionHeaders.length) {
					const [header] = state.versionHeaders.sort((a, b) => convertIsoToNumber(b.createdAt) - convertIsoToNumber(a.createdAt));

					if(header) {
						const version = await troubledBorrowerController.getVersion(header.versionId);

						commit(mutationTypes.SET_VERSION, mapper.map(version, ApiTroubledBorrowerVersion, TroubledBorrowerVersion));
					}
				}
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_VERSION_LOADING, false);
		}
	},
	async [actionTypes.fetchCompany]({ commit }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			const { companyId } = await troubledBorrowerController.getProject(router.currentRoute.params.projectId);
			const company = await troubledBorrowerController.getCompany(String(companyId));

			commit(mutationTypes.SET_COMPANY, company);
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.fetchOrganizations]({ commit }, query) {
		commit(mutationTypes.SET_IS_ORGANIZATIONS_LOADING, true);

		try {
			const organizations = await dictionariesController.getOrganizations(query);

			commit(mutationTypes.SET_ORGANIZATIONS, organizations);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ORGANIZATIONS_LOADING, false);
		}
	},
	async [actionTypes.fetchObligedCompanies]({ commit }, query) {
		commit(mutationTypes.SET_IS_OBLIGED_COMPANIES_LOADING, true);

		try {
			const obligedCompanies = await dictionariesController.getOrganizations(query);

			commit(mutationTypes.SET_OBLIGED_COMPANIES, obligedCompanies);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_OBLIGED_COMPANIES_LOADING, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit, state }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);

		try {
			const [
				eventTypes,
				requirementTypes,
				assessmentTypes,
				securityKindTypes,
				projectManagers,
				problemDebtDepartments,
				debtorStatusTypes,
				judicialDecisionTypes,
				refundStatusTypes,
				projectRoleTypes,
				refundSourceTypes,
				costTypes
			] = await Promise.all([
				dictionariesController.getEventTypes(),
				dictionariesController.getRequirementTypes(),
				dictionariesController.getAssessmentTypes(),
				dictionariesController.getSecurityKindTypes(),
				dictionariesController.getProjectManagerUsers(),
				dictionariesController.getProblemDeptDepartmentUsers(),
				dictionariesController.getDebtorStatusTypes(),
				dictionariesController.getJudicialDecisionTypes(),
				dictionariesController.getRefundStatusTypes(),
				dictionariesController.getProjectRoleTypes(),
				dictionariesController.getRefundSourceTypes(),
				dictionariesController.getCostTypes(),
			]);

			commit(mutationTypes.SET_EVENT_TYPES, eventTypes);
			commit(mutationTypes.SET_REQUIREMENT_TYPES, requirementTypes);
			commit(mutationTypes.SET_ASSESSMENTS_TYPES, assessmentTypes);
			commit(mutationTypes.SET_SECURITY_KIND_TYPES, securityKindTypes);
			commit(mutationTypes.SET_PROJECT_MANAGERS, projectManagers);
			commit(mutationTypes.SET_PROBLEM_DEBT_DEPARTMENTS, problemDebtDepartments);
			commit(mutationTypes.SET_DEBTOR_STATUS_TYPES, debtorStatusTypes);
			commit(mutationTypes.SET_JUDICIAL_DECISION_TYPES, judicialDecisionTypes);
			commit(mutationTypes.SET_REFUND_STATUS_TYPES, refundStatusTypes);
			commit(mutationTypes.SET_PROJECT_ROLE_TYPES, projectRoleTypes);
			commit(mutationTypes.SET_REFUND_SOURCE_TYPES, refundSourceTypes);
			commit(mutationTypes.SET_COST_TYPES, costTypes);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.createVersion]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const version = await troubledBorrowerController.createVersion(state.company.id, state.versionName);

			await dispatch(actionTypes.fetchVersionHeaders);

			commit(mutationTypes.SET_VERSION, mapper.map(version, ApiTroubledBorrowerVersion, TroubledBorrowerVersion));
			alertService.addInfo(AlertKeys.VERSION_SUCCESS_CREATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.fetchRefundSourceHistoryItem]({ commit, getters }, versionId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const items = await troubledBorrowerController.getRefundSourceHistoryHistory(versionId);
			commit(mutationTypes.SET_REFUND_SOURCE_HISTORY, mapper.mapArray(items, ApiRefundSourceHistory, RefundSourceHistory));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.fetchIncomePredictionHistory]({ commit }, versionId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const items = await troubledBorrowerController.getIncomePredictionHistory(versionId);
			commit(mutationTypes.SET_INCOME_PREDICTION_HISTORY, mapper.mapArray(items, ApiIncomePredictionHistory, IncomePredictionHistory));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteRefundSourceHistoryItem]({ commit, getters }, { historyItemId }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.deleteRefundSourceHistoryHistory(historyItemId);

			const items = state.refundSourceHistory.filter(x => x.id !== historyItemId);
			commit(mutationTypes.SET_REFUND_SOURCE_HISTORY, items);

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.fetchRefundStatusTypeHistory]({ commit }, versionId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const items = await troubledBorrowerController.getRefundStatusTypeHistory(versionId);
			commit(mutationTypes.SET_REFUND_STATUS_TYPE_HISTORY, mapper.mapArray(items, ApiRefundStatusTypeHistory, RefundStatusTypeHistory));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}

	}
};

const mutations = <MutationTree<TroubledBorrowerState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_VERSION](state, value) {
		state.version = cloneDeep(value);
	},
	[mutationTypes.SET_VERSION_HEADERS](state, value) {
		state.versionHeaders = value;
	},
	[mutationTypes.SET_COMPANY](state, value) {
		state.company = value;
	},
	[mutationTypes.SET_ORGANIZATIONS](state, value) {
		state.organizations = value;
	},
	[mutationTypes.SET_OBLIGED_COMPANIES](state, value) {
		state.obligedCompanies = value;
	},
	[mutationTypes.SET_IS_ORGANIZATIONS_LOADING](state, value) {
		state.isOrganizationsLoading = value;
	},
	[mutationTypes.SET_IS_OBLIGED_COMPANIES_LOADING](state, value) {
		state.isObligedCompaniesLoading = value;
	},
	[mutationTypes.SET_EVENT_TYPES](state, value) {
		state.eventTypes = value;
	},
	[mutationTypes.SET_REQUIREMENT_TYPES](state, value) {
		state.requirementTypes = value;
	},
	[mutationTypes.SET_ASSESSMENTS_TYPES](state, value) {
		state.assessmentTypes = value;
	},
	[mutationTypes.SET_SECURITY_KIND_TYPES](state, value) {
		state.securityKindTypes = value;
	},
	[mutationTypes.SET_PROJECT_MANAGERS](state, value) {
		state.projectManagers = value;
	},
	[mutationTypes.SET_PROBLEM_DEBT_DEPARTMENTS](state, value) {
		state.problemDebtDepartments = value;
	},
	[mutationTypes.SET_DEBTOR_STATUS_TYPES](state, value) {
		state.debtorStatusTypes = value;
	},
	[mutationTypes.SET_JUDICIAL_DECISION_TYPES](state, value) {
		state.judicialDecisionTypes = value;
	},
	[mutationTypes.SET_REFUND_STATUS_TYPES](state, value) {
		state.refundStatusTypes = value;
	},
	[mutationTypes.SET_IS_VERSION_HEADERS_LOADING](state, value) {
		state.isVersionHeadersLoading = value;
	},
	[mutationTypes.SET_IS_VERSION_LOADING](state, value) {
		state.isVersionLoading = value;
	},
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.RESET_VERSION_NAME](state) {
		state.versionName = "";
	},
	[mutationTypes.SET_VERSION_NAME](state, value) {
		state.versionName = value;
	},
	[mutationTypes.SET_PROJECT_ROLE_TYPES](state, value) {
		state.projectRoleTypes = value;
	},
	[mutationTypes.SET_REFUND_SOURCE_TYPES](state, value) {
		state.refundSourceTypes = value;
	},
	[mutationTypes.SET_REFUND_SOURCE_HISTORY](state, value) {
		state.refundSourceHistory = value;
	},
	[mutationTypes.SET_INCOME_PREDICTION_HISTORY](state, value) {
		state.incomePredictionHistory = value;
	},
	[mutationTypes.SET_REFUND_STATUS_TYPE_HISTORY](state, value) {
		state.refundStatusTypeHistory = value;
	},
	[mutationTypes.SET_COST_TYPES](state, value) {
		state.costTypes = value;
	},
};

const modules = {
	[mainInfoModule.namespace]: {
		...mainInfoModule
	},
	[proceedingStatusModule.namespace]: {
		...proceedingStatusModule
	},
	[provisionModule.namespace]: {
		...provisionModule
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	mainInfoModule.subscribe(store);
	proceedingStatusModule.subscribe(store);
	provisionModule.subscribe(store);

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.troubledBorrower.mainInfo.namespace, mainInfoTypes.mutationTypes.VERSION_UPDATED_EVENT):
			{
				commit(resolveMutation(namespace, mutationTypes.SET_VERSION), payload);

				await dispatch(resolveAction(namespace, actionTypes.fetchVersionHeaders));

				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, modules, subscribe
};

const troubledBorrowerModule = {
	namespace, state, getters, actions, mutations, modules, subscribe, namespaced: true
};

export default troubledBorrowerModule;
