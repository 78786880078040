<template>
	<v-card elevation="0" class="loan-card px-4 pt-4 pb-8 fill-height">
		<v-row>
			<v-col class="d-flex justify-space-between align-center">
				<span class="text-h6 blue--text text--darken-4">
					{{ $t("titles.refundSources") }}
				</span>
				<div>
					<frp-btn small
							 @click="handleOpenRefundSourceHistoryDialog()"
							 style="margin-left: 8px !important"
							 class="mr-1"
							 icon>
						<frp-icon src="ico_history" :color="colors.blue.darken4"></frp-icon>
					</frp-btn>
				</div>
			</v-col>
		</v-row>

		<v-row cols="5" class="mx-0 mt-3">
			<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
				<div>{{ $t("details.titles.refundSource.category") }}</div>
				<div>{{ $t("details.titles.refundSource.startDate") }}</div>
			</v-col>
			<v-col cols="2" class="pa-0 main-block-column">
				<div v-for="i in 2" :key="i"></div>
			</v-col>
			<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
				<div>
					<span v-if="mode === MainInfoModeType.READ">{{ refundSourceInfo.refundSourceType?.name ?? "Не выбрано" }}</span>
					<frp-autocomplete dense
									  :items="refundSourceTypes"
									  item-text="name"
									  item-value="id"
									  v-model="refundSourceType"
									  hide-details
									  v-else>
					</frp-autocomplete>
				</div>
				<div>
					<span v-if="mode === MainInfoModeType.READ">{{ formatDate(refundSourceInfo.startDate, dateFormat) }}</span>
					<frp-date-field dense
									required
									placeholder=""
									width="130px"
									v-model.number="startDate"
									hide-details
									v-else>
					</frp-date-field>
				</div>
			</v-col>
		</v-row>

		<frp-dialog max-width="565"
					v-model="isHistoryDialogOpened"
					:title="$t('dialogs.refundSourceHistory.title')">
			<template #content="{ onIntersect }">
				<div class="d-flex justify-center flex-column" style="gap: 8px" v-intersect="onIntersect">
					<frp-text-body-two v-if="!refundSourceHistory?.length"
									   class="grey--text align-self-center mt-6 mb-3">
						{{ $t("dialogs.debtorStatusHistory.noDataText") }}
					</frp-text-body-two>
					<div class="d-flex justify-space-between align-center" v-else v-for="(item, i) in refundSourceHistory" :key="i">
						<div class="d-flex flex-column justify-center">
							<span class="text-subtitle-2 primary--text">
								{{ item.refundSource.refundSourceType?.name || "Не выбрано" }}, c {{formatDate(item.refundSource.startDate, dateFormat) || "Не выбрано"}}
							</span>
							<span class="text-caption grey--text mt-1">
								{{item.createdBy}}, {{formatDate(item.createdAt, dateFormat)}}
							</span>
						</div>
						<frp-btn v-if="can(Permissions.LOAN_DELETE)"
								 small
								 @click="handleOpenDeleteItemDialog(item)"
								 icon>
							<frp-icon src="ico_delete" :color="colors.primary.base"></frp-icon>
						</frp-btn>
					</div>
				</div>
			</template>

			<template #footer>
				<frp-btn class="elevation-0"
						 @click="isHistoryDialogOpened = false"
						 :color="colors.blue.base">
					<span class="white--text">{{ $t("buttons.close") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isDeleteItemDialogOpened" :title="$t('dialogs.removeRefundSourceHistory.title')">
			<template #content>
				<span class="primary--text text-body-2">{{ $t('dialogs.removeRefundSourceHistory.confirm') }}</span>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="isDeleteItemDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 :loading="isFormSaving"
						 @click="handleDeleteHistoryItem"
						 :color="colors.blue.base">
					<span class="white--text">{{ $t("buttons.remove") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>

	</v-card>
</template>

<script>
	import FrpDateField from "Components/fields/FrpDateField";
	import FrpAutocomplete from "Components/fields/FrpAutocomplete";
	import FrpBtn from "Components/buttons/FrpBtn";
	import FrpIcon from "Components/icon/FrpIcon";
	import FrpDialog from "Components/dialogs/FrpDialog";
	import FrpTextBodyTwo from "Components/typography/FrpTextBodyTwo";
	import { formatDate } from "@/utils/dates";
	import { dateFormat } from "@/utils/formats";
	import { MainInfoModeType } from "Store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
	import {
		getterTypes,
		mutationTypes
	} from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types";
	import { actionTypes as historyActionTypes } from "@/store/loan/modules/troubledBorrower/types";
	import storeManager from "Store/manager";
	import { createNamespacedHelpers } from "vuex";
	import colorsMixin from "Mixins/colorsMixin";
	import authorizationMixin from "Mixins/authorizationMixin";
	import formMixin from "Mixins/formMixin";

	const namespace = storeManager.loan.troubledBorrower.mainInfo.namespace;
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
	const { mapState: troubledBorrowerMapState, mapGetters: troubledBorrowerMapGetters, mapActions: troubledBorrowerMapActions } = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

	export default {
		mixins: [colorsMixin, authorizationMixin, formMixin],
		data() {
			return {
				namespace,
				MainInfoModeType,
				dateFormat,
				formatDate,
				isHistoryDialogOpened: false,
				historyItemToDelete: null,
				isDeleteItemDialogOpened: false,
			};
		},
		computed: {
			...troubledBorrowerMapState({
				refundSourceTypes: state => state.refundSourceTypes,
				refundSourceHistory: state => state.refundSourceHistory,
			}),
			...mapState({
				editableVersion: state => state.editableVersion,
				mode: state => state.mode
			}),
			...mapGetters({
				version: getterTypes.version
			}),
			refundSourceInfo() {
				return this.version.refundSourceInfo;
			},
			refundSourceType: {
				get() {
					return this.editableVersion.refundSourceInfo.refundSourceType?.id;
				},
				set(value) {
					const refundSourceType = value != null ? this.refundSourceTypes.find(x => x.id == value) : null;
					this.setRefundSourceType(refundSourceType);
				}
			},
			startDate: {
				get() {
					return this.editableVersion.refundSourceInfo.startDate;
				},
				set(value) {
					this.setRefundSourceStartDate(value);
				}
			}
		},
		methods: {
			...mapMutations({
				setRefundSourceType: mutationTypes.SET_REFUND_SOURCE_TYPE_ID,
				setRefundSourceStartDate: mutationTypes.SET_REFUND_SOURCE_START_DATE
			}),
			...troubledBorrowerMapActions({
				fetchHistory: historyActionTypes.fetchRefundSourceHistoryItem,
				deleteRefundHistoryItem: historyActionTypes.deleteRefundSourceHistoryItem
			}),
			async handleOpenRefundSourceHistoryDialog() {
				await this.fetchHistory(this.version.versionHeader.versionId);
				this.isHistoryDialogOpened = true;
			},
			handleOpenDeleteItemDialog(item) {
				this.historyItemToDelete = item;
				this.isDeleteItemDialogOpened = true;
			},
			async handleDeleteHistoryItem() {
				await this.deleteRefundHistoryItem({ historyItemId: this.historyItemToDelete.id });
				this.historyItemToDelete = null;
				this.isDeleteItemDialogOpened = false;
			}
		},
		components: {
			FrpDateField,
			FrpAutocomplete,
			FrpBtn,
			FrpIcon,
			FrpDialog,
			FrpTextBodyTwo
		}
	}
</script>

<style scoped lang="scss">
	.main-block-column {
		& > div {
			height: 48px;
			display: flex;
			align-items: center;
		}

		& > div:nth-child(even) {
			background-color: var(--v-primary-lighten4);
		}
	}

	.main-block-column-left {
		& > div {
			padding-left: 16px;
		}
	}

	.main-block-column-right {
		& > div {
			padding-right: 16px;
		}
	}
</style>
